import React from 'react';
import { stringify } from 'rebem-classname';
import { enviarEventoGTM } from '../../../helpers/GTMHelper';

export default function CardListadoVerMasAnuncios(props) {
    const block = 'CardListadoMasAnuncios';
    function gtmButtonVerMas(){
        enviarEventoGTM('ver_anuncios_sin_precio', 'click', 'boton con resultado');
    }
    const handlerOnClick = () => {
        props.onBuscarPagina(props.paginaActual); 
        gtmButtonVerMas(); 
    }

    return (
        <div className={props.columna}>
            <div className={stringify({block, mods:{conMapa: props.mostrarMapa}})}>
                <div>
                    <div className={stringify({block, elem:'titulo'})}>
                        ¡Te mostramos alojamientos con precio a consultar!
                    </div>
                    <button className={`btn btn-celeste ${stringify({block, elem:'boton'})}`} onClick={() => handlerOnClick()}>
                        Ver más anuncios
                    </button>
                </div>
            </div>
        </div>
    );
}