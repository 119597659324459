import React from 'react';
import queryString from 'query-string';
import {Helmet} from "react-helmet";
import isMobile from 'ismobilejs';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import sum from 'lodash/sum';
import SchemaHelper from '../../helpers/SchemaHelper';
import FotosHelper from '../../helpers/FotosHelper';
import PrecioHelper from '../../helpers/PrecioHelper';
import CardListadoPrincipal from './CardListado/CardListadoPrincipal';
import CardListadoDetalles from './CardListado/CardListadoDetalles';
import CardListadoMapa from './CardListado/CardListadoMapa';
import CardListadoOferta from './CardListado/CardListadoOferta';
import CardListadoOfertaFlexible from './CardListado/CardListadoOfertaFlexible';
import { stringify } from 'rebem-classname';

export default class AnuncioCardListado extends React.Component {
	constructor(props) {
		super(props);
		this.onChangeCardListado = this.onChangeCardListado.bind(this);
		this.getDesglosePrecio = this.getDesglosePrecio.bind(this);
		this.getPreciosPorNoche = this.getPreciosPorNoche.bind(this);
		this.resetDesglose = this.resetDesglose.bind(this);
		this.selectItem = this.selectItem.bind(this);
		this.relocationLink = this.relocationLink.bind(this);
		
		this.state = {
			selected: 'Principal',
			info: null,
			hayDesglose: false,
		}	
	}

	getPreciosPorNoche(precios){
		this.setState({
			info: this.getDesglosePrecio(precios),
		});
	}

	resetDesglose() {
		this.setState({
			info: null
		});
	}

	componentWillReceiveProps(props) {
		const startDate= this.props.fechasDeBusqueda ? this.props.fechasDeBusqueda[0] : null;
		const endDate= this.props.fechasDeBusqueda ? this.props.fechasDeBusqueda[1] : null;
		const capacidad= this.props.personas;
		const condicion = startDate && endDate && capacidad;
		if (props.desgloseDePrecio && (props.desgloseDePrecio[this.props.propiedad.id] != this.props.desgloseDePrecio[this.props.propiedad.id])) {
			this.setState({
				hayDesglose: condicion
			}, 
				condicion ? 
					this.getPreciosPorNoche(props.desgloseDePrecio[this.props.propiedad.id]) : this.resetDesglose()
			);
		}
	}

	componentDidMount(){
		const startDate = this.props.fechasDeBusqueda ? this.props.fechasDeBusqueda[0] : null;
		const endDate = this.props.fechasDeBusqueda ? this.props.fechasDeBusqueda[1] : null;
		const capacidad = this.props.personas;
		const condicion = startDate && endDate && capacidad;

		this.setState({
			hayDesglose: condicion
		},
			condicion ?
				this.getPreciosPorNoche(this.props.desgloseDePrecio[this.props.propiedad.id]) : this.resetDesglose()
		);
	}

	getDesglosePrecio(precioPorFechas) {
		const { bebes, fechasDeBusqueda, propiedad } = this.props;
		const startDate = fechasDeBusqueda[0];
		const endDate = fechasDeBusqueda[1];
		const capacidad = this.props.personas;

		const agrupadoPorPrecio = groupBy(precioPorFechas, 'precio');

		const preciosPorNoche = map(agrupadoPorPrecio, (data, precio) => {
			return { 'precio': precio, 'noches': data.length }
		})

		let textoCapacidad = 'Huésped';
		let textoCantNoches = '';

		if (startDate && endDate) {
			let cantNoches = endDate.diff(startDate, 'days');
			textoCantNoches = cantNoches + (cantNoches > 1 ? ' noches' : ' noche') + ' para ';
		}

		if (capacidad != 0) {
			let textoBebe = '';
			if (bebes != 0) {
				textoBebe = ' + ' + bebes + ' bebe' + (bebes > 1 ? 's' : '')
			}
			textoCapacidad = capacidad + ' huésped' + (capacidad > 1 ? 'es' : '') + textoBebe;
		}

		let textoCantidad = textoCantNoches + textoCapacidad;

		const celNumber = propiedad.cargo_extra_limpieza || 0;
		const ceoNumber = propiedad.cargo_extra_otro || 0;

		const preciosPorRangos = map(preciosPorNoche, (p) => {
			return p.precio * p.noches
		});
		preciosPorRangos.push(celNumber, ceoNumber);
		const total = sum(preciosPorRangos);

		return {
			total: total,
			tienePrecio: preciosPorNoche.length,
			seña: propiedad.senia ? (total * propiedad.senia) / 100 : null,
			textoCantidad: textoCantidad,
			preciosPorNoche: preciosPorNoche
		};
	}

	onChangeCardListado(evt){
		let atributo = evt.currentTarget.getAttribute('data-selected');
		if(atributo != undefined && atributo != this.state.selected){
			this.setState({
				selected: atributo
			});
		}else{
			this.setState({
				selected: 'Principal'
			});
		}	
	}

	relocationLink(link) {
		let auxLink = link;
		const parseQuery = queryString.parse(location.search);
		if (parseQuery.test && parseQuery.test == 1) {
			auxLink += '?test=1';
		}
		if (isMobile.any) {
			window.location.href = auxLink;
			return;
		}
		window.open(auxLink, '_blank');
	}

	selectItem(evt) {
		evt.preventDefault();
		
		const { propiedad, lugar, fechasDeBusqueda, index } = this.props;

		const bookedDays = fechasDeBusqueda[0] && fechasDeBusqueda[1] ? fechasDeBusqueda[1].diff(fechasDeBusqueda[0], "days") : null;

		const selectItem = {
			event: "select_item",
			ecommerce: {
				items: [{
					item_name: `${propiedad.referencia} - ${propiedad.titulo}`,
					item_id: propiedad.referencia,
					item_list_name: lugar.nombre ? `Listado de ${lugar.nombre}` : "Listado",
					item_list_id: lugar.nombre ? lugar.nombre.toLowerCase().replaceAll(" ", "_") : "listado",
					index: index + 1
				}]
			}
		}

		if(bookedDays) {
			selectItem.ecommerce.items[0]['quantity'] = bookedDays; //Cantidad de noches SOLO SI TENEMOS EL DATO
		}

		if(propiedad.precio_minimo_calculado && bookedDays) {
			selectItem.ecommerce.items[0]['price'] = propiedad.precio_minimo_calculado / bookedDays; //Comisión CON IVA / cant de Noches SOLO SI TENEMOS EL DATO
		}

		window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.

		window.dataLayer.push(selectItem);

		this.relocationLink(propiedad.link);

		return;
	}
	
	render() {
		const p = this.props.propiedad;
		const tieneOfertas = p.tieneOfertas;
		const tieneOfertasUm = p.ofertas_um.length;
		const tieneOfertasFlexibles = p.ofertas_flexibles.length;

		let auxLink = "";
		if(this.props.arrayParaLink){
			auxLink += "?"+queryString.stringify(this.props.arrayParaLink);
		}
		const parseQuery = queryString.parse(location.search);
		if (parseQuery.test && parseQuery.test == 1) {
			auxLink += (auxLink ? '&' : '?')+'test=1';
		} 
		const link = this.props.propiedad.link+auxLink;

		let display = null
		switch (this.state.selected) {
			case 'Principal':
				display = <CardListadoPrincipal
					showModalFavorito={this.props.showModalFavorito}
					dataPush={this.selectItem}
					mostrar={this.props.mostrar}
					propiedad={this.props.propiedad}
					tieneOfertas={tieneOfertas}
					tieneOfertasUm={tieneOfertasUm}
					tieneOfertasFlexibles={tieneOfertasFlexibles}
					onChangeCardListado={this.onChangeCardListado}
					link={link}
					info={this.state.info}
					esEscapada={this.props.esEscapada}
					cargando={false}
					hayDesglose={this.state.hayDesglose && this.state.info}
				/>;
				break;
			case 'Mapa':
				display = <CardListadoMapa
					lat={p.lat}
					lng={p.lng}
					referencia={p.referencia}
					es_troya={p.es_troya}
				/>
				break;
			case 'Detalles':
				display = <CardListadoDetalles
					dormitorios={p.dormitorios}
					banios={p.banios}
					pileta={p.ag_pileta}
					cochera={p.ag_cochera}
					ascensor={p.ag_ascensor}
					wifi={p.ag_wifi}
					aire_acondicionado={p.ag_aire_acondicionado}
					ropa_cama={p.ag_ropa_cama}
					ropa_blanca={p.ag_ropa_blanca}
					desayuno={p.ag_desayuno}
					asador={p.ag_asador}
					tv={p.ag_tv}
				/>;
				break;
			case 'Oferta':
				display = tieneOfertas ? <CardListadoOferta
					propiedad={this.props.propiedad}
					onChangeCardListado={this.onChangeCardListado}
					fechasDeBusqueda={this.props.fechasDeBusqueda}
				/> : null;
				break;
			case 'OfertaFlexible':
				display = tieneOfertasFlexibles ? <CardListadoOfertaFlexible
						vistaDual={this.props.mostrarMapa}
						propiedad={this.props.propiedad}
						onChangeCardListado={this.onChangeCardListado}
				/> : null;
				break;
		}

		const modificadorPara = (valor,valorEscapada) => {
			return {
				conMapa:valor ? true : false,
				escapada:valorEscapada ? true : false,
			}
		}

		const mods = {
			esLineaGold:!!this.props.propiedad.esLineaGold,
			esLineaDestacados:!!this.props.propiedad.esLineaDestacados,
			esDestacada:this.props.propiedad.esDestacada,
		}
		
		const title = 'Ver ' + this.props.propiedad.tipo; 
		const block = 'AnuncioCardListado';
		const tieneUbicacion = !!this.props.propiedad.lat && !!this.props.propiedad.lng;
		const imagenListado = FotosHelper.imgParaPropiedad(this.props.propiedad.referencia, this.props.propiedad.foto_listado);

		let telefonos = this.props.propiedad.telefonos ? JSON.parse(this.props.propiedad.telefonos) : null;

		telefonos = map(telefonos, (t)=>{
            return t.telefonoWeb;
		});
		
		const precio = this.props.propiedad.precio_minimo_calculado;
		let formattedPrecio = precio ?  PrecioHelper.formatearPrecio(precio) : null;
		let precioSchema = formattedPrecio ? `El mejor precio, desde ${formattedPrecio} ARS por noche.`: null; 

		return(
			<div data-referencia={p.referencia} className={`${this.props.columnas} ${stringify({block})}`}>
				<div className={stringify({block, elem:"espacioPrincipal", mods:modificadorPara(this.props.mostrarMapa,this.props.esEscapada)})}>
					{display}
				</div>
				<Helmet script={[SchemaHelper.schema(this.props.propiedad, precioSchema, imagenListado, 'Descripción no disponible', telefonos, false)]} />
			</div>
		);
	}
}