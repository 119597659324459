import React, { useState } from "react";
import isMobile from 'ismobilejs';
import PrecioHelper from '../../../helpers/PrecioHelper';
import Dialog from '@mui/material/Dialog';
import { styled } from "@mui/material/styles";
import { enviarEventoGTM } from '../../../helpers/GTMHelper';
import HelperPalabrasEnPlural from "../../../helpers/HelperPalabrasEnPlural";
import { Grid } from "@mui/material";
import styles from "./CardPrecioStyle";

function enviarGtmDesglose(sendEvent){
    if(sendEvent){
        enviarEventoGTM('desglose_precio', 'click', 'abrio el desglose de precio del listado');
    }
}

const Precio = (props)=>{
    const cambiarCondicionesParaClassName = props.hayDesglose && props.info && props.info.tienePrecio;

    return <Grid item container xs={12} data-tour="siro_precio_mobile" css={cambiarCondicionesParaClassName ? {...styles.precio, ...styles.precioSmall} : styles.precio}>
        {
            !props.cargando ? props.precio : ""
        }
        <Grid item component="span" css={cambiarCondicionesParaClassName ? {...styles.precioTag, ...styles.precioTagSmall} : styles.precioTag}>{props.estimado ? 'estimado' : '/noche'}</Grid>
    </Grid>
}

export default function CardPrecio(props){
    if (!props.precio){
        return null;
    }

    const [showDesglose, onClickDesglose] = useState(false);
    const [showModal, onClickModal] = useState(false);
    
    const DialogCustom = styled(Dialog)(() => ({
        "& .MuiDialog-paper": {
            padding: 15,
            width: "80%"
        }
    }));

    const precioTroya = <Grid item>
        {/*se deshabilita hasta que haya descuentos*/false ? <Grid item container>
            <Grid item component="p">{PrecioHelper.formatearPrecio(3200)}</Grid>
            <Grid item>-15%</Grid>
        </Grid> : null}
        
        <Precio cargando={props.cargando} precio={props.precio} estimado={props.estimado} hayDesglose={props.hayDesglose} info={props.info} />
        
        {props.hayDesglose && props.info && props.info.tienePrecio ?
            !props.cargando ? 
            <Grid item container>
                {
                props.info.preciosPorNoche.map((p, key) => {
                    const cantNoches = `${p.noches} ${HelperPalabrasEnPlural(
                        p.noches,
                        "noche"
                      )}`

                    return (<Grid item xs={12} key={key} css={styles.precio}>
                        {PrecioHelper.formatearPrecio(p.noches * p.precio)} <Grid item component="span" css={styles.precioTag}>x {cantNoches}</Grid>
                    </Grid>)
                    }
                )
                }
            </Grid>
                : 
                <Grid item></Grid>
            : null
        }
    </Grid>;

    let precio = <Grid onMouseEnter={() => {onClickDesglose(true); enviarGtmDesglose(true)}} onMouseLeave={() => onClickDesglose(false)}>
            {precioTroya}
        </Grid>;

    return (precio);
}