import React from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { stringify } from 'rebem-classname';
import ContenidoFiltros from './ContenidoFiltros.jsx';

export default function FiltrosMobileContenido(props){
	const block = 'FiltrosMobileContenido';
	const cabeceraMobile = <div className={stringify({block, elem:'contenedor-botones'})}>
		<button className={stringify({block, elem:'boton-cruz'})} onClick={props.closeModal}><RiCloseFill /></button>
		<span className={`btn-noButton ${stringify({block, elem:'boton-filtros'})}`} >Filtros activados ({props.filtrosAplicados})</span>
		<button className={`btn-noButton ${stringify({block, elem:'boton-quitar'})}`} onClick={props.onRestablecerFiltros}>Quitar Filtros</button>
	</div>;

	const buscarMobile = <div className={stringify({block, elem:'contenedor-buscar'})}>
		<button className={stringify({block, elem:'boton-buscar'})} onClick={props.onBuscar}>Buscar</button>
	</div>;
	
	return (
		<div className={stringify({block})}>
			{cabeceraMobile}
			<ContenidoFiltros
				hayTroyaEnElListado={props.hayTroyaEnElListado}
				onClickCheck={props.onClickCheckFiltros}
				onClickCheckLocalidades={props.onClickCheckLocalidadesFiltros}
				onLugarClear={props.onLugarClear} 
				onLugarSelected={props.onLugarSelected}
				cochera={props.cochera}
				dormitorios={props.dormitorios}
				banos={props.banos}
				onPlus={props.onPlus}
				onMinus={props.onMinus}
				localidades={props.localidades}			
				ofertasOnChange={props.ofertasOnChangeFiltros}
				previajeOnChange={props.previajeOnChangeFiltros}
				reservaOnChange={props.reservaOnChangeFiltros}
				reserva={props.reserva}
				onChangePrecio={props.onChangePrecioFiltros}
				conPileta={props.conPileta}
				sinPileta={props.sinPileta}
				PiletaExclusiva={props.PiletaExclusiva}
				Piletaclimatizada={props.Piletaclimatizada}
				Casas={props.Casas}
				Departamentos={props.Departamentos}
				Cabanas={props.Cabanas}
				Aparts={props.Aparts}
				Hoteleria={props.Hoteleria}
				Quintas={props.Quintas}
				Motorhomes={props.Motorhomes}
				Glampings={props.Glampings}
				ofertas={props.ofertas}
				previaje={props.previaje}
				conMascotas={props.conMascotas}
				conJovenes={props.conJovenes}
				wifi={props.wifi}
				tarjeta={props.tarjeta}
				desayuno={props.desayuno}
				limpieza={props.limpieza}
				toallas={props.toallas}
				sabanas={props.sabanas}
				spa={props.spa}
				juegosParaNinios={props.juegosParaNinios}
				restaurante={props.restaurante}
				min={props.min}
				max={props.max}
				currentValues={props.currentValues}
				agregado1={props.agregado1}
				agregado2={props.agregado2}
				agregado3={props.agregado3}
				localidades_seleccionadas={props.localidades_seleccionadas}
				lugar={props.lugar}
				referencia={props.referencia}
				onReferenciaChange={props.onReferenciaChange}
				onBarrioSelected={props.onBarrioSelected}
				onBarrioClear={props.onBarrioClear}
				barrio={props.barrio}
				aireAcondicionado={props.aireAcondicionado}
				calefaccion={props.calefaccion}
				microondas={props.microondas}
				televisor={props.televisor}
				lavarropa={props.lavarropa}
				alojamientoAccesible={props.alojamientoAccesible}
				precioPromedio={props.precioPromedio}
				alojamientosPremium={props.alojamientosPremium}
				alojamientosDestacados={props.alojamientosDestacados}
				seleccionesOtros={props.seleccionesOtros}
			/>
			{buscarMobile}
		</div>
	);
}