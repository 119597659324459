const HelperPalabrasEnPlural = (cantidad, palabra) => {
  const terminacion = palabra.slice(-1);
  let plural;

  if (/[aeiou]/i.test(terminacion)) {
    plural = palabra + "s";
  } else {
    plural = palabra + "es";
  }

  return cantidad > 1 ? plural : palabra;
};

export default HelperPalabrasEnPlural;
