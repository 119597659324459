import React from 'react';
//import AsyncImport from "../../../components/Commons/AsyncImport";
import Dialog from '@mui/material/Dialog';
import BotoneraListado from '../BotoneraListado';
import {ComponentWithBackButtonSupport} from '../../Abstract/ComponentWithBackButtonSupport';
import FiltrosMobileContenido from './FiltrosMobileContenido';
import {FaFilter} from 'react-icons/fa';
/*const FiltrosMobileContenido = AsyncImport({
	loader: () => import('./FiltrosMobileContenido'),
	loading: () => null
});*/

export default class FiltrosMobile extends ComponentWithBackButtonSupport {
	constructor(props) {
		super(props);
		this.state = {
			primeraCarga:true,
			visible: this.props.filtrosOpen || this.shouldBeVisible()
		}
	}

	componentWillReceiveProps(nextProps){
		if (nextProps.cerrarElementos){
			this.hide();
		} else if(nextProps.abrirModal){
			this.show();
		}
	}

	render() {
		return (
			<div style={{display:'inline-block'}}>
				<BotoneraListado onClickEvent={this.show} icono={<FaFilter />} titulo="FILTROS"/>
				<Dialog 
					fullScreen
					open={this.state.visible}
					onClose={this.hide}
				>
					<FiltrosMobileContenido 
						{...this.props}
						closeModal={this.hide}
					/>
				</Dialog>
			</div>
		);
	}
}