import React from 'react';
import 'rc-checkbox/assets/index.css';
import Checkbox from 'rc-checkbox';
import { Collapse } from 'react-bootstrap'

export default class FiltrosCollapseLocalidades extends React.Component{
	constructor(props) {
		super(props);
		this.openCollapseFiltros = this.openCollapseFiltros.bind(this);
		this.state = {
			show:false,
		}
	}
	openCollapseFiltros(){
		this.setState({
			show: !this.state.show,
		});
	}
	render(){
		return (
			<div className="collapse-localidades">
				<div className="collapse-localidades__cabecera">
					<a type="button" onClick={this.openCollapseFiltros}>
						<div className="collapse-localidades__titulo collapse-localidades--inline">{this.props.titulo}</div>
						<div className="collapse-localidades__boton collapse-localidades--inline collapse-localidades--izq">
								<span className="collapse-input__ver-mas" >Ver más</span>
								<span className={"glyphicon glyphicon-menu-"+(this.state.show? "up" :"down")}/>
						</div>
					</a>
				</div>
				<Collapse in={this.state.show}>
					<div className="collapse-localidades_container">
						<div className="collapse-localidades">
							<div className="collapse-localidades__checks">
								{this.props.localidades.map((loc) => {
									return  (
										<div className="collapse-localidades__item-check" key={loc.id}>
											<label className="collapse-localidades__label">
												<Checkbox
													name={loc.id.toString()}
													checked={this.props.localidades_seleccionadas.indexOf(loc.id.toString()) === -1 ? false : true}
													onClick={this.props.toggleCheckLocalidad}
												/>
												<span className="collapse-localidades__label-text">{loc.localidad}</span>
											</label>
										</div>);
									})
								}
							</div>
						</div>
					</div>
				</Collapse>
			</div>
		);		
	}
}