import React from 'react';
import 'rc-checkbox/assets/index.css';
import Checkbox from 'rc-checkbox';


export default function FiltrosSelectorChecks(props){
	return (
		<div className="selector-checks">
			<div className="selector-checks__checks">
				<div className="selector-checks__item-check">
					<label className="selector-checks__label">
						<Checkbox
							name={'conMascotas'}
							checked={props.conMascotas}
							onClick={props.toggle}
						/>
						<span className="selector-checks__label-text">VIAJO CON MASCOTAS</span>
					</label>
				</div>
				<div className="selector-checks__item-check">
					<label className="selector-checks__label">
						<Checkbox
							name={'conJovenes'}
							checked={props.conJovenes}
							onClick={props.toggle}
						/>
						<span className="selector-checks__label-text">SOMOS JÓVENES</span>
					</label>
				</div>
				<div className="selector-checks__item-check">
					<label className="selector-checks__label">
						<Checkbox
							name={'wifi'}
							checked={props.wifi}
							onClick={props.toggle}
						/>
						<span className="selector-checks__label-text">WI-FI</span>
					</label>
				</div>
				<div className="selector-checks__item-check">
					<label className="selector-checks__label">
						<Checkbox
							name={'tarjeta'}
							checked={props.tarjeta}
							onClick={props.toggle}
						/>
						<span className="selector-checks__label-text">PAGO CON TARJETA</span>
					</label>
				</div>
			</div>
		</div>
	);
}