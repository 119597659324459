import axios from 'axios';
import map from 'lodash/map'
import isObject from 'lodash/isObject'

var instance = axios.create();

export default class AjaxHelper{
	static error(responseError){
		if(responseError.response && responseError.response.data && isObject(responseError.response.data)){
			alert(map(responseError.response.data, (errors, field) => {
				return errors.join('\n');
			}).join('\n'));
		}
		else if(responseError.response && responseError.response.status == 400){
			alert(responseError.response.data)
		}
		else{
			alert(responseError.message);
		}
	}
};
