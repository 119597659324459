import React, {useRef, useState, useEffect} from 'react';
import AsyncImport from "../../components/Commons/AsyncImport";
import BotoneraListado from './BotoneraListado';
import { DisplayListadoLocalidadesCercanasMobile } from './DisplayListadoLocalidadesCercanasMobile';
import { DisplayListadoSinPropiedadesMobile } from './DisplayListadoSinPropiedadesMobile';
import CardListadoVerMasAnuncios from './CardListado/CardListadoVerMasAnuncios.jsx';
import ClickOutside from 'react-click-outside';
import AnuncioCardListado from './AnuncioCardListado';
import ModalMapaListado from './Modales/ModalMapaListado';
import FiltrosMobile from './Filtros/FiltrosMobile';
import Dialog from '@mui/material/Dialog';
import Toggle from 'react-toggle';
import CardListadoBannerBrasil from './CardListado/CardListadoBannerBrasil';
import { enviarEventoGTM } from '../../helpers/GTMHelper';
import {AiOutlineAlignLeft} from 'react-icons/ai';
import {HiOutlineHeart} from 'react-icons/hi';
import Context  from '../Login/ContextUser/Context';

// import DropdownOrdenar from './DropdownOrdenar';
const DropdownOrdenar = AsyncImport({
	loader: () => import('./DropdownOrdenar'),
	loading: () => null
});
import PaginatorListado from '../../components/Commons/PaginatorListado';
import { stringify } from 'rebem-classname';
/*const PaginatorListado = AsyncImport({
	loader: () => import('../../components/Commons/PaginatorListado'),
	loading: () => null
});*/


const ListadoRenderItems = ({...props}) => {
	return <div className={`row ${stringify({block:props.block, elem:"display"})}`}>
		<div className={`col-xs-12 ${stringify({block:props.block, elem:"listado", mods:{ cargando: props.estaCargando }})}`}>
			{props.paginator.destacada ? <div className="row">
				<AnuncioCardListado
					showModalFavorito={props.showModalFavorito}
					listParaGTM={props.listParaGTM}
					propiedad={props.paginator.destacada}
					columnas="col-sm-offset-3 col-sm-6 col-xs-12"
					mostrarMapa={true}
					lugar={props.paginator.lugar}
					estaCargando={props.estaCargando}
					personas={props.personas}
					bebes={props.bebes}
					fechasDeBusqueda={[props.startDate, props.endDate]}
					desgloseDePrecio={props.paginator.desgloseDePrecio}
				/>
			</div> : null}
			<div className="row">
				{
					props.propiedades.map((propiedad, index) => <AnuncioCardListado
						key={`${propiedad.esLineaGold ? 'g' : ''}${propiedad.esLineaDestacados ? 'd' : ''}${propiedad.referencia}`}
						showModalFavorito={props.showModalFavorito}
						listParaGTM={props.listParaGTM}
						propiedad={propiedad}
						columnas="col-sm-6 col-xs-12"
						mostrarMapa={true}
						lugar={props.paginator.lugar}
						estaCargando={props.estaCargando}
						personas={props.personas}
						bebes={props.bebes}
						fechasDeBusqueda={[props.startDate, props.endDate]}
						desgloseDePrecio={props.paginator.desgloseDePrecio}
						index={index}
					/>)
				}
				{
					// si esta habilitado en config cat, si esta en el listado de brasil y si esta en la ultima pagina del listado muestro el banner
					props.showBanner && props.lugar && props.lugar.nombre == 'Brasil' && props.paginator.current_page == props.paginator.last_page ? 
						<div className='col-sm-6 col-xs-12'>
							<CardListadoBannerBrasil mobile/> 
						</div>
					: null
				}
				{
					!props.paginator.listado_especial && props.paginator.hay_anuncios_sin_precio && !props.paginator.acepta_anuncios_sin_precios && props.paginator.current_page == props.paginator.last_page ?
						<CardListadoVerMasAnuncios
							columna="col-sm-6 col-xs-12"
							onBuscarPagina={props.onBuscarPagina}
							paginaActual={props.paginator.current_page}
							mostrarMapa={true}
						/>
						: null
				}
			</div>
			<div className={stringify({block:props.block, elem:"paginador"})} id="paginador">
				<PaginatorListado
					prev={props.paginator.prev_page_url != null}
					next={props.paginator.next_page_url != null}
					ellipsis
					boundaryLinks
					items={props.paginator.last_page}
					maxButtons={3}
					activePage={props.paginator.current_page}
					onSelect={props.onGoToPage} />
			</div>
		</div>
	</div>;
}

const GetListadoMobile = (props) =>{
	let display = <ListadoRenderItems {...props}/>;

	if (!props.propiedades.length) {
		display = <DisplayListadoSinPropiedadesMobile block={props.block} abrirFiltros={props.abrirFiltros} />
	}
	
	if (props.paginator.localidadesCercanas) {
		let tituloSeo = props.tituloSeo;
		let textoSeoAbajoH1 = props.textoSeoAbajoH1;
		tituloSeo = 'Alquileres en ' + props.paginator.localidadSinLugar.localidad + ', ' + props.paginator.localidadSinLugar.provincia;
		textoSeoAbajoH1 = 'Encontrá los mejores alquileres en ' + props.paginator.localidadSinLugar.localidad + ', ' + props.paginator.localidadSinLugar.provincia;

		display = <DisplayListadoLocalidadesCercanasMobile block={props.block} estaCargando={props.estaCargando} dataLocalidadesCercanas={props.paginator.dataLocalidadesCercanas} />;
	}

	return display;
}

export default class MainListadoMobile extends React.PureComponent{

	constructor(props) {
		super(props);
		this.mostrarMapa = this.mostrarMapa.bind(this);
		this.toogleDropdownOrdenar = this.toogleDropdownOrdenar.bind(this);
		this.onItemSeleccionadoDropdownOrdenar = this.onItemSeleccionadoDropdownOrdenar.bind(this);
		this.ocultarDropdownOrdenar = this.ocultarDropdownOrdenar.bind(this);
		this.abrirFiltros = this.abrirFiltros.bind(this);
		this.cerrarFiltros = this.cerrarFiltros.bind(this);
		this.hideModalFavorito = this.hideModalFavorito.bind(this);
		this.showModalFavorito = this.showModalFavorito.bind(this);
		this.gtmButtonVerMas = this.gtmButtonVerMas.bind(this);

		this.state = {
			mostrarMapa:false,
			visibleDropdownOrdenar:false,
			abrirFiltros:false,
			openModal:false,
			showBanner: true
		}
	}

	showModalFavorito() {
		this.setState({
			openModal: true
		})
	}

	hideModalFavorito() {
		this.setState({
			openModal: false
		})
	}

	mostrarMapa(){	
		if(document.body){
			if (!this.state.mostrarMapa) {
				document.body.className = "modal-open";
			} else {
				document.body.className = "";
			}
		}
		this.setState({
			mostrarMapa: !this.state.mostrarMapa
		});
	}

	toogleDropdownOrdenar(){
		this.setState({
			visibleDropdownOrdenar:!this.state.visibleDropdownOrdenar
		});
	}

	onItemSeleccionadoDropdownOrdenar(orden){
		this.props.onOrdenChange(orden);
		this.ocultarDropdownOrdenar();
	}

	ocultarDropdownOrdenar(){
		this.setState({visibleDropdownOrdenar:false});
	}

	cerrarFiltros() {
		this.setState({abrirFiltros: false},this.props.onBuscar);
	}

	abrirFiltros(){
		this.setState({abrirFiltros: true});
	}

	gtmButtonVerMas(){
		enviarEventoGTM('ver_anuncios_sin_precio', 'click', 'boton sin resultado');
	}

	componentDidMount() {
		const { getSession } = this.context;
		getSession();
	};
	
	render(){
		const block = 'MainListado';

		const { 
			paginator,
			propiedades_para_mapa
		} = this.props;

		let tituloSeo = paginator.tituloSeo;
		let textoSeoAbajoH1 = paginator.textoSeoAbajoH1;

		const propsParaFiltros = {
			filtrosOpen: paginator.filtrosOpen,
			localidades: paginator.localidadesFiltro,
			onBuscar:this.cerrarFiltros,
			hayTroyaEnElListado:paginator.hayTroyaEnElListado
		}

		const render = <>
			<div className={stringify({block})}>
				<Dialog open={this.state.openModal} onClose={this.hideModalFavorito}>
					<div className={stringify({block, elem:"contenido-modal-fav"})}>
						<div className={stringify({block, elem:"texto-modal-fav"})}>Para usar la sección FAVORITOS debes estar logueado a la página.</div>
						<div className={stringify({block, elem:"texto-modal-fav"})}>Para iniciar sesión o registrarte haz click en acceder.</div>
						<a href={this.props.urlLogin} className="btn btn-naranja">Acceder</a>
					</div>
				</Dialog>
				<h1 className={stringify({block, elem:"descripcion"})}>{tituloSeo}</h1>
				<p className={stringify({block, elem:"descripcion-sub"})}>{textoSeoAbajoH1}</p>
				{!paginator.localidadesCercanas ? <div className={stringify({block, elem:"botonera"})}>
					<div className={stringify({block, mods:{ iconos: true }})}>
						<FiltrosMobile
							urlKey="mf"
							lugar={this.props.lugar}
							abrirModal={this.state.abrirFiltros}
							{...this.props}
							{...propsParaFiltros}
						/>
						<ClickOutside style={{ display: 'inline-block' }} onClickOutside={this.ocultarDropdownOrdenar}>
							<BotoneraListado
								onClickEvent={this.toogleDropdownOrdenar}
								icono={<AiOutlineAlignLeft />}
								titulo="ORDENAR"
							/>
							<DropdownOrdenar
								visible={this.state.visibleDropdownOrdenar}
								onItemSeleccionado={this.onItemSeleccionadoDropdownOrdenar}
								itemSeleccionado={this.props.orden}
							/>
						</ClickOutside>
						<ModalMapaListado
							filtros={paginator.filtrosUrl}
							lugar={paginator.lugar}
							page={paginator.current_page}
							mapaOpen={paginator.mapaOpen}
						/>
						<div className={stringify({block, elem:"iconos-item"})}>
							<a href='/favoritos/'>
								<div className={stringify({block, elem:"iconos-iconos"})}>
									<HiOutlineHeart />
								</div>
								<div className={stringify({block, elem:"iconos-titulo"})}>
									<span>FAVORITOS</span>
								</div>
							</a>
						</div>
						<div className={stringify({block, elem:"reserva-online-toggle"})}>
							<span className={stringify({block, elem:"texto-toggle"})}>SÓLO RESERVA ONLINE</span>
							<Toggle
								checked={this.props.reserva}
								onChange={this.props.soloReservaOnlineOnChangeFiltros}
							/>
						</div>	
					</div>
				</div> : null}
			</div>
			<GetListadoMobile 
				{...this.props}
				block={block}
				showModalFavorito={this.showModalFavorito}
				abrirFiltros={this.abrirFiltros}
				showBanner={this.state.showBanner}
				lugar={this.props.lugar}
				gtmButtonVerMas={this.gtmButtonVerMas}
			/>
		</>;

		return(
			!paginator.listadoCustom ? render : 
			<div className={`container-fluid ${stringify({block})}`}>
				{paginator.hotelesMarDel.length ? <div>
					<div className={`col-md-8 ${stringify({block, elem:"header"})}`} style={{ marginBottom: 35, textAlign: 'center' }}>
						<h1 style={{ fontSize: '1.5em' }} className={stringify({block})}>Hoteles baratos en Mar del Plata hasta 300 $ por día</h1>
					</div>
					<div className="row">
						{
							paginator.hotelesMarDel.map((p, i) => <AnuncioCardListado
								key={`${p.esLineaGold ? 'g' : ''}${p.esLineaDestacados ? 'd' : ''}${p.referencia}`}
								listParaGTM={this.props.listParaGTM}
								propiedad={p}
								columnas="col-sm-6 col-xs-12"
								mostrarMapa={true}
								lugar={paginator.lugar}
								estaCargando={this.props.estaCargando}
								personas={this.props.personas}
								bebes={this.props.bebes}
								fechasDeBusqueda={[this.props.startDate, this.props.endDate]}
								index={i}							
							/>)
						}
					</div>
				</div> : null}
				<div className={`col-md-12 ${stringify({block, elem:"header"})}`} style={{ marginBottom: 35, textAlign: 'center' }}>
					<h2 style={{ fontSize: '1.2em' }} className={stringify({block})}> Las mejores alternativas a Hoteles en Mar Del Plata - Precios Accesibles y Amplia Disponibilidad</h2>
				</div>
				<GetListadoMobile 
					{...this.props}
					showBanner={this.state.showBanner}
					block={block}
					showModalFavorito={this.showModalFavorito}
					abrirFiltros={this.abrirFiltros}
					lugar={this.props.lugar}
					gtmButtonVerMas={this.gtmButtonVerMas}
				/>
			</div>
		);
	}
}

MainListadoMobile.contextType = Context;