import React from 'react';
import CardListadoDetallesImagen from './CardListadoDetallesImagen';
import SliderCardListado from './SliderCardListado';
import {BsChevronRight} from 'react-icons/bs';
import { stringify } from 'rebem-classname';

export default class CardImagen extends React.Component {
	constructor(props) {
		super(props);
		this.AbrirSlider = this.AbrirSlider.bind(this);
		this.state = {
			mostrarSlider: false
		}
	}

	AbrirSlider(evt){
		evt.preventDefault();
		evt.stopPropagation();
		this.setState({mostrarSlider: true});
	}

	render() {
		//esta const es para probar dejar el slider al cargar o abrirlo en segunda carga
		const SliderEnPrimeraCarga = true;
		const block = 'AnuncioCardListado';

		let display = <div className={stringify({block, elem:"display"})}>	
				<div className={stringify({block, elem:"imagen"})}>
					<a onAuxClick={this.props.dataPush} onClick={this.props.dataPush} href={this.props.link} target='_blank'>
						<img className={stringify({block, elem:"fotos"})} src={ this.props.fotoPrincipal.small } sizes="(max-width: 768px) calc(100vw - 30px), (max-width: 992px) calc(50vw - 30px), (max-width: 1200px) calc(33vw - 30px), calc (20vw - 30px)" srcSet={ this.props.srcSet } alt={ this.props.altImg } title={ this.props.altImg } />
					</a>
				</div>
				<div className={stringify({block, elem:'botones-slider'})}>
					{!this.props.esEscapada&&<button className={`btn btn-noButton ${stringify({block, elem:'next'})}`} style={{display:'block'}} onClick={this.AbrirSlider}>
						<BsChevronRight />
					</button>}
				</div>
				<CardListadoDetallesImagen
					favorito={this.props.favorito}
					tieneOfertas={this.props.tieneOfertas}
					tieneOfertasUm={this.props.tieneOfertasUm}
					tieneOfertasFlexibles={this.props.tieneOfertasFlexibles}
					propiedad={this.props.propiedad}
					onChangeCardListado={this.props.onChangeCardListado}
					fotoPerfil={this.props.fotoPerfil}
					showModalFavorito={this.props.showModalFavorito}
					block="CardListadoDetallesImagen"
					link={this.props.link}
				/>
			</div>;

		if ((SliderEnPrimeraCarga || this.state.mostrarSlider)&&!this.props.esEscapada) {
			display = <SliderCardListado
						dataPush={this.props.dataPush}
						mostrar={this.props.mostrar}
						referencia={this.props.propiedad.referencia}
						fotoPrincipal={this.props.fotoPrincipal}
						altImg={this.props.altImg}
						srcSet={this.props.srcSet}
						showModalFavorito={this.props.showModalFavorito}
						favorito={this.props.favorito}
						fotos={this.props.propiedad.fotos}
						tipo={this.props.propiedad.tipo}
						localidad={this.props.propiedad.nombre_localidad}
						tieneOfertas={this.props.tieneOfertas}
						tieneOfertasUm={this.props.tieneOfertasUm}
						tieneOfertasFlexibles={this.props.tieneOfertasFlexibles}
						propiedad={this.props.propiedad}
						onChangeCardListado={this.props.onChangeCardListado}
						fotoPerfil={this.props.fotoPerfil}
						link={this.props.link}
					/>;
		}

		return (
			<React.Fragment>{display}</React.Fragment>
		);
	}
}