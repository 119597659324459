import React from 'react';
import OfertaFlexibleHelper from '../../../helpers/OfertaFlexibleHelper';
import orderBy from 'lodash/orderBy';
import isMobile from 'ismobilejs';
import EtiquetaReserva from '../../Commons/EtiquetaReserva';
import { stringify } from 'rebem-classname';
import { ImFire } from 'react-icons/im';

function armarLeyendasSegunEstados(premium, destgold, destacado){
	let salida = [];
	// premium; gold > destacado; visto > nuevo;
	if(premium){
		salida.push(
			{'orden':'0', 'leyenda':'Premium'});
	}
	if(destgold){
		salida.push(
			{'orden':'1.1', 'leyenda':'Gold'});
	} else if(destacado){
		salida.push(
			{'orden':'2.1', 'leyenda':'Destacado'});
	}

	//cuando vengan visto y/o nuevo, usar:
	//{'orden':'1.2', 'leyenda':'visto' , 'icono':"glyphicon glyphicon-eye-open"},
	//{'orden':'2.2', 'leyenda':'nuevo', 'icono':"glyphicon glyphicon-asterisk"}

	salida = orderBy(salida, 'orden', 'asc');
	return salida;
}

export default function CardListadoLeyenda({premium, destgold, destacado, es_troya, tieneOfertas, tieneOfertasUm, tieneOfertasFlexibles, onChangeCardListado, dataPush, link, propiedad}){
	const mostrarReservaParaSic = propiedad.permite_reservas && !propiedad.precio_estimado && propiedad.precio_minimo_calculado
	const _helper = new OfertaFlexibleHelper();
	const block = 'CardListadoLeyenda';
	const mostrarOfertasFlexibles = _helper.getOfertaAMostrar(propiedad.ofertas_flexibles);
	const leyendas = armarLeyendasSegunEstados(premium, destgold, destacado);
	const estadoPara = (valor, solo) =>{ 
		return {
			premium:valor=='Premium',
			gold:valor=='Gold',
			destacado:valor=='Destacado',
			vistosSolo:solo,
		}
	}
	let ofertas = [];
	let ofertasConModificador = [];
	let etiquetaReserva = null;
	//Validacion para que solo se muestren las OUM en caso de tener OUM Y OfertaFlexible
	if(tieneOfertas && tieneOfertasUm){
		ofertas = <div className={stringify({block, elem:"oferta-con-leyenda"})} title="Oferta Imperdible!" data-selected={'Oferta'} onClick={onChangeCardListado}>
					<ImFire />
				</div>
		ofertasConModificador = <div title="Oferta Imperdible!" style={{display: tieneOfertas && tieneOfertasUm ? 'inline-block' : 'none'}} className={stringify({block, elem:"oferta-con-leyenda", mods:{oferta:true}})} data-selected={'Oferta'} onClick={onChangeCardListado}>
					<ImFire />
				</div>
	} else if(tieneOfertasFlexibles && mostrarOfertasFlexibles){
		ofertas = <span title="Oferta Flexible!"  className={`icon-coupon ${stringify({block, elem:"ofertaFlexible"})}`} data-selected={'OfertaFlexible'} onClick={onChangeCardListado}/>
		ofertasConModificador = <span title="Oferta Flexible!" style={{display: tieneOfertasFlexibles && mostrarOfertasFlexibles ? 'inline-block' : 'none'}} className={`icon-coupon ${stringify({block, elem:"ofertaFlexible", mods:{oferta:true}})}`} data-selected={'OfertaFlexible'} onClick={onChangeCardListado}/>
	}

	if (es_troya || mostrarReservaParaSic) {
		etiquetaReserva = <div className={stringify({block, elem:"es_troya"})}>
			<a className={stringify({block, elem:"enlace"})} onAuxClick={dataPush} onClick={dataPush} href={link} target={isMobile.any ? '_self' : '_blank'}>
				<EtiquetaReserva />
			</a>
		</div>;
	}
	
	if(leyendas.length != 0){
		let leyendasHtml = [];
		if(leyendas.length > 1){
			for (let i = 0; i < 2; i++) {
				if(leyendas[i]['leyenda'] === 'Gold'){
					leyendasHtml[i] = 
						<div key={i} className={stringify({block,elem:"vistos-item", mods:{'Gold-premium':true}})}>
							<span className={stringify({block, elem:"texto"})}>{leyendas[i]['leyenda']}</span>
						</div>
				} else if(leyendas[i]['leyenda'] === 'Destacado'){
					leyendasHtml[i] = 
						<div key={i} className={stringify({block,elem:"vistos-item", mods:{'Destacado-premium':true}})}>
							<span className={stringify({block, elem:"texto"})}>{leyendas[i]['leyenda']}</span>
						</div>
				} else {
					leyendasHtml[i] = 
						<div key={i} className={stringify({block, elem:"vistos-item", mods:estadoPara(leyendas[i]['leyenda'],true)})}>
							<span className={stringify({block, elem:"texto"})}>{leyendas[i]['leyenda']}</span>
						</div>
				}
			}
		} else {
			leyendasHtml = 
				<div className={stringify({block, elem:"vistos-item", mods:estadoPara(leyendas[0]['leyenda'],true)})} >
					<span className={stringify({block, elem:"texto"})}> {leyendas[0]['leyenda']}</span>
				</div>
		}
		return 	<>
					<div className={stringify({block, elem:"vistos", mods:estadoPara(leyendas[0]['leyenda'],true)})}>
						<a className={stringify({block, elem:"enlace"})} onAuxClick={dataPush} onClick={dataPush} href={link} target={isMobile.any ? '_self' : '_blank'}>
							{leyendasHtml}
						</a>
						{ofertas}
					</div>
					{etiquetaReserva}
				</>
	} else {
		if (es_troya || mostrarReservaParaSic){
			return <> 
					{etiquetaReserva}
				</>
		}
		else{
			return 	<div className={stringify({block, elem:"vistos"})}>
					{ofertasConModificador}
				</div>
		}
	}
}