import React from 'react';
import {Pagination} from 'react-bootstrap';
import qs from 'query-string';
import floor from 'lodash/floor';

export default  ({items, maxButtons, activePage}) => {
	const itemsRender = [];
	const lastItems = items - maxButtons;
	let startIndex = 1;
	if (items > maxButtons && activePage > lastItems) {
		startIndex = lastItems;
	} else if (activePage >= maxButtons) {
		startIndex = activePage - floor(maxButtons/2);
	}
	let endIndex = items;
	if (activePage <= lastItems) {
		if (activePage > lastItems || activePage >= maxButtons) {
			endIndex = activePage + floor(maxButtons/2);
		} else if(activePage < maxButtons) {
			endIndex = maxButtons;
		}
	}
	const parsed = qs.parse(location.search);
	for(let i = startIndex; i <= endIndex; i++){
		itemsRender.push(<Pagination.Item key={i} active={activePage==i} href={`?${qs.stringify({...parsed, pag:i})}/`}>{i}</Pagination.Item>);
	}
	const start = <>
		{activePage != 1 && <Pagination.Prev href={`?${qs.stringify({...parsed, pag:activePage-1})}`}/>}
		{activePage >= maxButtons && <>
			<Pagination.Item active={activePage==1} href={`?${qs.stringify({...parsed, pag:1})}/`}>1</Pagination.Item>
			<Pagination.Ellipsis />
		</>}
	</>;
	const end = <>
		{maxButtons <= (items - activePage) && <Pagination.Ellipsis />}
		{activePage <= lastItems && <Pagination.Item active={activePage==items} href={`?${qs.stringify({...parsed, pag:items})}/`}>{items}</Pagination.Item>}
		{activePage != items && <Pagination.Next href={`?${qs.stringify({...parsed, pag:activePage+1})}/`}/>}
	</>;
	return <Pagination>
		{start}
		{itemsRender}
		{end}
	</Pagination>;
}