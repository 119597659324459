import React from 'react';
import Rheostat from 'rheostat';


export default function SelectorDePrecio(props){
	const precioMin = props.currentValues ? props.currentValues[0] : props.min; 
	const precioMax = props.currentValues ? props.currentValues[1] : props.max; 
	const display = props.precioPromedio ? <div className="rango-precio__leyenda rango-precio--font-leyenda">el precio promedio es de ${props.precioPromedio} por noche</div> : null;
	return (
		<div className="rango-precio">
			<div className="rango-precio__titulo">RANGO DE PRECIOS</div>
			<div className="rango-precio__texto-valor rango-precio--font-leyenda">de ${precioMin} a ${precioMax} o más</div>
			{display}
			<div className="rango-precio__container">
				<Rheostat
					min={props.min}
					max={props.max}
					values={[precioMin, precioMax]}
					onValuesUpdated={props.onChange}
				/>
			</div>
		</div>
	);
}