import React from 'react';
import FotosHelper from '../../../helpers/FotosHelper';
import { enviarEventoGTM } from '../../../helpers/GTMHelper';

function clickBannerBrasil(){
    enviarEventoGTM('partnership', 'click', 'banner_temporadalivre');
}

export default function CardListadoBannerBrasil(props){
	const divStyle = {
		width: props.mobile ? '100%' : '38%',
		marginLeft: props.mobile ? null : '31%',
		marginBottom: '20px',
	};

    return(		
        <div style={divStyle}>
            <a href="https://www.temporadalivre.com/es" target="_blank">
                <img src={FotosHelper.imgV4("bannerBrasil.png")} alt="banner" onClick={clickBannerBrasil} width="100%"/>
            </a>
        </div>
    );
}