import React from 'react';
import { stringify } from 'rebem-classname';
import MapBoxFicha from '../../Mapbox/MapBoxFicha.jsx';

export default function CardListadoMapa(props){
	const block = "CardListadoMapa";
	const style = {
		position: 'absolute',
		height: '98%',
		width: '100%',
		left: 0,
		zIndex: 0,
	};

	if(!!props.lat && !!props.lng){
		return(		
			<div className={stringify({block})}>
				<MapBoxFicha referencia={props.referencia} lat={props.lat} lng={props.lng} usarPointer={props.es_troya} style={style} />
			</div>
		);
	} else {
		return null;
	}
}