import React from 'react';
import Slider from "react-slick";
import FotosHelper from '../../../helpers/FotosHelper';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import CardListadoDetallesImagen from './CardListadoDetallesImagen';
import debounce from 'lodash/debounce';
import isMobile from 'ismobilejs';
import { stringify } from 'rebem-classname';

export default class SliderCardListado extends React.Component {
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.mostrarLeyenda = this.mostrarLeyenda.bind(this);
		this.imagen = this.imagen.bind(this);

		this.mostrarLeyendaDebounced = debounce(() => {
			this.setState({
				showLeyenda: true
			});
		}, 2000);

		this.state = {
			showLeyenda: true,
			pagesToPreload: 1
		}
	}
	componentWillUnmount(){
		this.mostrarLeyendaDebounced.cancel()
	}
	next() {
		this.slider.slickNext();
		this.mostrarLeyenda();
		this.setState({
			pagesToPreload: 3
		});
	}
	previous() {
		this.slider.slickPrev();
		this.mostrarLeyenda();
	}
	helperFoto(referencia, archivo) {
		const foto = {
			small: FotosHelper.imgParaPropiedad(referencia, archivo, 'listado_sm'),
			medium: FotosHelper.imgParaPropiedad(referencia, archivo, 'listado_md'),
		}
		return foto;
	}
	srcSet(foto) {
		return foto.small + ' 320w, ' + foto.medium + ' 640w';
	}
	mostrarLeyenda() {
		this.setState({
			showLeyenda: false
		});
		this.mostrarLeyendaDebounced();
	}

	imagen(foto, block) {
		return <img className={stringify({block, elem:"fotos"})} src={FotosHelper.imgParaPropiedad(this.props.referencia, foto.nombreArchivo, 'listado_sm')}
			sizes="(max-width: 768px) calc(100vw - 30px), (max-width: 992px) calc(50vw - 30px), (max-width: 1200px) calc(33vw - 30px), calc (20vw - 30px)"
			srcSet={this.srcSet(this.helperFoto(this.props.referencia, foto.nombreArchivo))}
			alt={'Alquiler ' + this.props.tipo + ' en ' + this.props.localidad}
		/>;
	}

	render() {
		const block = 'AnuncioCardListado';
		const settings = {
			focusOnSelect: false,
			dots: true,
			arrows: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			swipeToSlide: true,
			centerMode: true,
			centerPadding: "0",
			lazyLoad: true,
			pagesToPreload: this.state.pagesToPreload
		};

		return (
			<div key={this.props.mostrar} style={{ position: 'relative' }}>
				<Slider ref={c => this.slider = c} {...settings}>
					{
						this.props.fotos.map((foto, index) =>
							<div key={index} className={stringify({block, elem:"display"})}>
								<div className={stringify({block, elem:"imagen"})}>
									{isMobile.any ?
										<a onAuxClick={this.props.dataPush} onClick={this.props.dataPush} href={this.props.link}>
											{this.imagen(foto, block)}
										</a> :
										<a onAuxClick={this.props.dataPush} onClick={this.props.dataPush} href={this.props.link} target='_blank'>
											{this.imagen(foto, block)}
										</a>
									}
								</div>
							</div>
						)
					}
				</Slider>
				<div className={stringify({block, elem:'botones-slider'})}>
					<button className={`btn btn-noButton ${stringify({block, elem:'prev'})}`} onClick={this.previous}>
						<BsChevronLeft />
					</button>
					<button className={`btn btn-noButton ${stringify({block, elem:'next'})}`} onClick={this.next}>
						<BsChevronRight />
					</button>
				</div>
				<div style={{ display: this.state.showLeyenda ? 'block' : 'none' }}>
					<CardListadoDetallesImagen
						favorito={this.props.favorito}
						tieneOfertas={this.props.tieneOfertas}
						tieneOfertasUm={this.props.tieneOfertasUm}
						tieneOfertasFlexibles={this.props.tieneOfertasFlexibles}
						propiedad={this.props.propiedad}
						onChangeCardListado={this.props.onChangeCardListado}
						fotoPerfil={this.props.fotoPerfil}
						showModalFavorito={this.props.showModalFavorito}
						block="CardListadoDetallesImagen2"
						link={this.props.link}
					/>
				</div>
			</div>
		);
	}
}