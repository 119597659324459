import React from 'react';
import { stringify } from 'rebem-classname';

export default function BotoneraListado({ titulo, icono, onClickEvent }){
	const block="MainListado";
	return <div className={stringify({block, elem:"iconos-item"})}>
		<div className={stringify({block, elem:"iconos-iconos"})}>
			<button className={`btn-noButton ${stringify({block,elem:"boton-filtro"})}`} onClick={onClickEvent}>
				{icono}
			</button>
		</div>
		<div className={stringify({block, elem:"iconos-titulo"})} onClick={onClickEvent}>
			<span>{titulo}</span>
		</div>
	</div>;
}