import React, { useEffect } from 'react';
import L from 'leaflet';
import { } from 'mapbox-gl-leaflet';
import FotosHelper from "../../helpers/FotosHelper";

import 'mapbox-gl/dist/mapbox-gl.css';
import 'leaflet/dist/leaflet.css';

export default function MapBoxFicha(props) {
    let mapContainer;
    let referencia = props.referencia + Math.random();
    useEffect(() => {
        const { lat, lng, usarPointer, hideCargando, mostrarUbicacionExacta } = props;

        const map = L.map(mapContainer, { attributionControl: true }).setView([lat, lng], 15);
        const mapStyle = 'https://maps.geoapify.com/v1/styles/osm-bright/style.json';

        L.mapboxGL({
            style: `${mapStyle}?apiKey=${process.env.MIX_GEOAPIFY_KEY}`,
            zoomOffset: -1,
        }).addTo(map);

        ubicarPropiedad(map, lat, lng, usarPointer, mostrarUbicacionExacta);
 
        map.attributionControl.setPrefix('Powered by <a rel="nofollow" href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a rel="nofollow" href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>')
 
        if (hideCargando) {
            hideCargando();
        }
    }, [mapContainer]);

    const ubicarPropiedad = (map, lat, lng, usarPointer, mostrarUbicacionExacta) => {
        // si es siro o si es sic y elige mostrar la ubicacion exacta se muestra el puntero con la ubicacion exacta de la propiedad
        if (usarPointer || mostrarUbicacionExacta) {
            return L.marker([lat, lng], { icon: FotosHelper.markerAA(L) }).addTo(map);
        } else {
            return L.circle([lat, lng], {
                color: '#4bbec5',
                fillColor: '#4bbec5',
                fillOpacity: 0.5,
                radius: 350
            }).addTo(map);
        }
    }

    return (
        <div className="mapbox container">
            <div style={props.style} id={referencia} ref={el => mapContainer = el}></div>
        </div>
    );
}