import React from 'react';
import 'rc-checkbox/assets/index.css';
import Checkbox from 'rc-checkbox';
import { stringify } from 'rebem-classname';


export default function FiltrosSelectorPileta(props){
	const block = "selector-pileta";
	return (
			<div className={stringify({block})}>
				<div className={stringify({block, elem:"titulo"})}>PILETA</div>
				<div className={stringify({block, elem:"checks"})}>
					<div className={stringify({block, elem:"item-check"})}>
						<label className={stringify({block, elem:"label"})}>
							<Checkbox
								name={'conPileta'}
								checked={props.conPileta}
								onClick={props.toggle}
							/>
							<span className={stringify({block, elem:"label-text"})}>Si</span>
						</label>
					</div>
					<div className={stringify({block, elem:"item-check"})}>
						<label className={stringify({block, elem:"label"})}>
							<Checkbox
								name={'sinPileta'}
								checked={props.sinPileta}
								onClick={props.toggle}
							/>
							<span className={stringify({block, elem:"label-text"})}>No</span>
						</label>
					</div>
					<div className={stringify({block, elem:"item-check"})}>
						<label className={stringify({block, elem:"label"})}>
							<Checkbox
								name={'PiletaExclusiva'}
								checked={props.PiletaExclusiva}
								onClick={props.toggle}
							/>
							<span className={stringify({block, elem:"label-text"})}>Exclusiva</span>
						</label>
					</div>
					<div className={stringify({block, elem:"item-check"})}>
						<label className={stringify({block, elem:"label"})}>
							<Checkbox
								name={'Piletaclimatizada'}
								checked={props.Piletaclimatizada}
								onClick={props.toggle}
							/>
							<span className={stringify({block, elem:"label-text"})}>Climatizada</span>
						</label>
					</div>
				</div>
			</div>
	);
}