import React from 'react';
import AsyncImport from "../../../components/Commons/AsyncImport";
import BotoneraListado from '../BotoneraListado';
import Dialog from '@mui/material/Dialog';
import pick from 'lodash/pick';
import find from 'lodash/find';
import QSUtil from '../../../helpers/QSUtil';
import UrlHelper from '../../../helpers/Filtros/UrlHelper';
import axios from 'axios';
import { stringify } from 'rebem-classname';
import {FaMapMarkerAlt} from 'react-icons/fa';
import {IoIosArrowBack} from 'react-icons/io';

//import MapBoxListado from '../../Mapbox/MapBoxListado';
const MapBoxListado = AsyncImport({
	loader: () => import('../../Mapbox/MapBoxListado'),
	loading: () => null
});
//import MapaPie from '../Mapa/MapaPie';
const MapaPie = AsyncImport({
	loader: () => import('../Mapa/MapaPie'),
	loading: () => null
});

export default class MapaParaModal extends React.Component{
	constructor(props) {
		super(props);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.shouldBeVisible = this.shouldBeVisible.bind(this);
		this.openModal = this.openModal.bind(this);
		this.urlHelper = new UrlHelper();

		this.state = {
			primeraCarga:true,
			visible: this.props.mapaOpen || this.shouldBeVisible(),
			propiedades: null,
			lugarAnterior: null,
			filtroAnterior: null
		}
	}

	shouldBeVisible(){
		return QSUtil.includes(this.props.urlKey) || localStorage.getItem('v') == 'm';
	}

	show() {
		const { lugar, page, filtros} = this.props
		
		let prov = null
		if(lugar){
			if(filtros == this.state.filtroAnterior && lugar.nombre == this.state.lugarAnterior){
				this.openModal()
				return;
			}
			
			if (lugar.tipo == 'localidad'){
				prov = lugar.provincia
			} else if (lugar.tipo == "provincia" || lugar.tipo == "país"){
				prov = lugar.nombre
			}
		}

		let datos = {
			filtros: filtros,
			localidad: lugar && lugar.tipo == 'localidad' ? lugar.nombre : null,
			// Si no viene lugar muestro el mapa de toda argentina
			provincia: lugar ? prov : 'Argentina',
			queryString: { pag: page, p: undefined }
		}

		axios
		.get(this.urlHelper.buildAjaxMapaUrl(datos))
		.then((response) => {
			this.openModal(response.data)
		})
		.catch((err) => console.log(err));
	}

	openModal(propiedades = null){
		this.setState(prevState => ({
			visible: true,
			primeraCarga: false,
			propiedades: propiedades ? propiedades : prevState.propiedades,
			lugarAnterior: this.props.lugar ? this.props.lugar.nombre : null,
			filtroAnterior: this.props.filtros
		}), () => {
			localStorage.setItem('v', 'm');
		})
	}

	hide() {
		this.setState({
			visible: false,
			primeraCarga:false
		}, () => {
			localStorage.setItem('v', 'l');
		});
	}

	render(){
		const mapaStyle = {
			width: '100%',
			height: '100%',
			left: 0,
			top: 60,
			margin: 0,
			padding: 0,
			position: 'absolute',
			zIndex: 0,
		};

		if (!this.state.propiedades) {
			return <BotoneraListado onClickEvent={this.show} icono={<FaMapMarkerAlt />} titulo="MAPA" />
		}

		const block='ModalMapaListado';
		return(
			<div style={{display:'inline-block'}}>
				<BotoneraListado onClickEvent={this.show} icono={<FaMapMarkerAlt />} titulo="MAPA" />
				<Dialog open={this.state.visible} onClose={this.hide} fullScreen>
					<div className={stringify({block, elem:"contenido"})}>
						<div className={stringify({block, elem:"mapa-titulo"})}>
							<div className={stringify({block, elem:"flecha-mapa"})} onClick={this.hide}>
								<IoIosArrowBack />
							</div>
							MAPA
						</div>
						<div className="MapaListado__mapa" style={{height:this.props.mapaHeight}}>
							{this.state.visible && <MapBoxListado
								style={mapaStyle}
								propiedades={this.state.propiedades}
								key={this.state.visible ? 'visible' : 'invisible(?'}
								onClickMarker={this.props.onChangeClick}
							/>}
						</div>
					</div>
					<div className={stringify({block, elem:"mapa-pie"})}>
						<MapaPie 
							dual={false}
							propiedad={pick(find(this.state.propiedades, {referencia: this.props.propMostrar}), ['referencia','foto_listado','tipo','precio_minimo_calculado','capacidad_max','nombre_localidad','nombre_provincia','puntaje','titulo','link'])} 
							CerrarMapaPie={this.props.CerrarMapaPie}
							mostrarMapaPie={this.props.mostrarMapaPie}
						/>
					</div>
				</Dialog>
			</div>
		);
	}
}