import React from 'react';
import { stringify } from 'rebem-classname';

export default function EtiquetaReserva(props){


    // mobile viene como undefined en el listado, queda funcionando así hasta tener mejor solución
    const esAppListado = window.location.host == 'app.alquilerargentina.com' && !props.mobile;
    const esAppFicha = window.location.host == 'app.alquilerargentina.com' && props.mobile;

    const block = "EtiquetaReserva";
    return <div className={stringify({block, elem:"etiqueta-reserva", mods:{mobile: props.mobile, ficha: props.cambioEtiqueta, esAppFicha: esAppFicha, esAppListado: esAppListado}})}>
        <div className={stringify({block, elem:"etiqueta-reserva-text", mods:{ mobile: props.mobile }})}>
            Reserva online
        </div>
        <div className={stringify({block, elem:"etiqueta-reserva-icon", mods:{ mobile: props.mobile, ficha: props.cambioEtiqueta, esAppListado: esAppListado }})}>
            <div className={stringify({block, elem:"etiqueta-reserva-circle", mods:{ mobile: props.mobile }})}>
                <span className="icon-cursor"></span>
            </div>
        </div>
    </div>;
}