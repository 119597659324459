import React from 'react';
import 'rc-checkbox/assets/index.css';
import Checkbox from 'rc-checkbox';
import { Collapse } from 'react-bootstrap'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

export default class FiltrosCollapseChecks extends React.Component{
	constructor(props) {
		super(props);
		this.openCollapseFiltros = this.openCollapseFiltros.bind(this);

		this.state = {
			show:false,
		}
	}
	openCollapseFiltros(){
		this.setState({
			show: !this.state.show,
		});
	}
	render(){
		return (
			<div className="collapse-checks">
				<a onClick={this.openCollapseFiltros}>
					<div className="collapse-checks__cabecera">
							<div className="collapse-checks__titulo collapse-checks__inline">{this.props.titulo}</div>
							<div className="collapse-checks__boton collapse-checks__inline collapse-checks__izq">
								<span className="collapse-input__ver-mas" >Ver más</span>
								<span>{this.state.show ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
							</div>
					</div>
				</a>	
				<Collapse in={this.state.show}>
					<div className="collapse-checks_container">
						<div className="collapse-checks">
							<div className="collapse-checks__checks">
								{
									this.props.checks.map(function(check){
										return <div key={check.name} className="collapse-checks__item-check">
											<label className="collapse-checks__label">
												<Checkbox
													name={check.name}
													checked={check.state}
													onClick={check.toggle}
												/>
												<span className="collapse-checks__label-text">{check.label}</span>
											</label>
										</div>
									})
								}
							</div>
						</div>
					</div>
				</Collapse>
			</div>
		);
	}
}