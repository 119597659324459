import React from 'react';
import CardListadoleyenda from './CardListadoleyenda';
import Favoritos from '../../Commons/Favoritos';
import isMobile from 'ismobilejs';
import { stringify } from 'rebem-classname';

export default function CardListadoDetallesImagen(props){
	const block = props.block;
	const esFav = window.g.favoritos ? window.g.favoritos.indexOf(props.propiedad.referencia) != -1 : false;
	const esVisto = window.g.vistos ? window.g.vistos.indexOf(props.propiedad.referencia) != -1 : false;
	const esConsultado = window.g.consultados ? window.g.consultados.indexOf(props.propiedad.referencia)!=-1:false;

	let displayInteraccion = null
	if (esVisto && esConsultado) {
		displayInteraccion = <div className={stringify({block, elem:"interaccion-consultado"})}>
								Consultado
							</div>
	} else if(esVisto) {
		displayInteraccion = <div className={stringify({block, elem:"interaccion-visto"})}>
								Visto
							</div>
	}
	return(
		<React.Fragment>
			<div className={stringify({block, elem:"atributos"})}>
				<Favoritos 	
					conFondo={false}
					referencia={props.propiedad.referencia}
					idTurista={window.g.turista?window.g.turista.id:null}
					fav={esFav}
					showModalFavorito={props.showModalFavorito}
				/>
			</div>
			<a onAuxClick={props.dataPush} onClick={props.dataPush} href={props.link} target={isMobile.any ? '_self' : '_blank'}>
				<div className={stringify({block, elem:"contenedor-interacciones"})}>
					{displayInteraccion}
				</div>
				<div className={stringify({block, elem:"fotoPerfil"})}>
					{props.fotoPerfil}
				</div>
			</a>
			<div className={stringify({block, elem:"leyenda"})}>
				<CardListadoleyenda 
					sinFotoPerfil={props.fotoPerfil==null}
					premium={props.propiedad.es_premium}
					propiedad={props.propiedad}
					es_troya={props.propiedad.es_troya}
					destgold={props.propiedad.es_destacado_gold}
					destacado={props.propiedad.es_destacado}
					tieneOfertas={props.tieneOfertas}
					tieneOfertasUm={props.tieneOfertasUm}
					tieneOfertasFlexibles={props.tieneOfertasFlexibles}
					onChangeCardListado={props.onChangeCardListado}
					dataPush={props.dataPush}
					link={props.link}
				/> 
			</div>
		</React.Fragment>
	);
}