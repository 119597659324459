import React from 'react';
import SelectorDePrecio from './SelectorDePrecio.jsx';
import FiltrosSelectorTipoPropiedad from './FiltrosSelectorTipoPropiedad.jsx';
import FiltrosSelectorPileta from './FiltrosSelectorPileta.jsx';
import FiltrosSelectorChecks from './FiltrosSelectorChecks.jsx';
import FiltrosCollapseChecks from './FiltrosCollapseChecks.jsx';
import FiltrosCollapseInput from './FiltrosCollapseInput.jsx';
import FiltrosCollapseLocalidades from './FiltrosCollapseLocalidades.jsx';
import SelectorDeLugaresParaFiltros from './SelectorDeLugaresParaFiltros.jsx';
import Toggle from 'react-toggle';
import isMobile from 'ismobilejs';
import AutocompleteBarrios from '../../Commons/AutocompleteBarrios.jsx';
import { stringify } from 'rebem-classname';

export default function ContenidoFiltros(props){
	const inputReferencia = <input className="react-autosuggest__input" onChange={props.onReferenciaChange} value={props.referencia||''}/>;
	const AutocompleteBarrio = <AutocompleteBarrios 
		onLugarClear={props.onBarrioClear} 
		onLugarSelected={props.onBarrioSelected} 
		barrio={props.barrio}
		lugar={props.lugar}
		/>;

	const mostrarFiltroLocalidad = props.localidades ? true:false;
	const filtroLocalidad = mostrarFiltroLocalidad ? <FiltrosCollapseLocalidades
				titulo={'LOCALIDADES'}
				toggleCheckLocalidad={props.onClickCheckLocalidades}
				localidades = {props.localidades}
				localidades_seleccionadas = {props.localidades_seleccionadas ? props.localidades_seleccionadas : []}
			/> : null;

	const block = 'ContenidoFiltros';
	const checkEquipamientos =[
		{
			name:'aireAcondicionado',
			state:props.aireAcondicionado,
			label:'Aire acondicionado',
			toggle:props.onClickCheck,
		},
		{
			name:'calefaccion',
			state:props.calefaccion,
			label:'Calefacción',
			toggle:props.onClickCheck,
		},
		{
			name:'microondas',
			state:props.microondas,
			toggle:props.onClickCheck,
			label:'Microondas',
		},
		{
			name:'televisor',
			state:props.televisor,
			label:'Televisor',
			toggle:props.onClickCheck,
		},
		{
			name:'lavarropa',
			state:props.lavarropa,
			label:'Lavarropa',
			toggle:props.onClickCheck,
		},
		{
			name:'alojamientoAccesible',
			state:props.alojamientoAccesible,
			label:'Alojamiento Accesible',
			toggle:props.onClickCheck,
		},
	];
	const checkSelecciones =[
		{
			name:'alojamientosPremium',
			state:props.alojamientosPremium,
			label:'Alojamientos Premium',
			toggle:props.onClickCheck,
		},
		{
			name:'alojamientosDestacados',
			state:props.alojamientosDestacados,
			label:'Alojamientos Destacados',
			toggle:props.onClickCheck,
		},
		{
			name:'seleccionesOtros',
			state:props.seleccionesOtros,
			label:'Otros',
			toggle:props.onClickCheck,
		},
	];
	const checkServicio = [
		{
			name:'desayuno',
			state:props.desayuno,
			label:'Desayuno',
			toggle:props.onClickCheck,
		},
		{
			name:'limpieza',
			state:props.limpieza,
			label:'Limpieza periódica',
			toggle:props.onClickCheck,
		},
		{
			name:'toallas',
			state:props.toallas,
			label:'Toallas',
			toggle:props.onClickCheck,
		},
		{
			name:'sabanas',
			state:props.sabanas,
			label:'Sabanas',
			toggle:props.onClickCheck,
		},
		{
			name:'spa',
			state:props.spa,
			label:'Spa',
			toggle:props.onClickCheck,
		},
		{
			name:'juegosParaNinios',
			state:props.juegosParaNinios,
			label:'Juegos Para Niños',
			toggle:props.onClickCheck,
		},
		{
			name:'restaurante',
			state:props.restaurante,
			label:'Restaurante',
			toggle:props.onClickCheck,
		}
	];

	return (
		<div className={`container-fluid ${stringify({block})}`}>
			<FiltrosSelectorTipoPropiedad
				Casas ={props.Casas}
				hayTroya={props.hayTroyaEnElListado}
				Departamentos ={props.Departamentos}
				Cabanas ={props.Cabanas}
				Aparts ={props.Aparts}
				Hoteleria ={props.Hoteleria}
				Quintas ={props.Quintas}
				Motorhomes={props.Motorhomes}
				Glampings={props.Glampings}
				toggle={props.onClickCheck}
			/>
			<hr />
			{props.hayTroyaEnElListado ? <>
					<div className={stringify({block, elem:"reserva"})}>
						<div className={stringify({block, elem:"reserva-titulo"})}>
							<div className={stringify({block, elem:"reserva-titulo-span"})}>SÓLO RESERVA ONLINE</div>
						</div>
						<div className={stringify({block, elem:"reserva-toggle-check"})} mods={{ derecha: isMobile.any }}>
							<Toggle
								checked={props.reserva}
								onChange={props.reservaOnChange}
							/>
						</div>
					</div>
				<hr />
			</>  : null}
			<SelectorDePrecio
				min={props.min}
				max={props.max}
				currentValues={props.currentValues ? [props.currentValues.min,props.currentValues.max]:[0,0]}
				onChange={props.onChangePrecio}
				precioPromedio={props.precioPromedio}
			/>
			<hr />
			<div className="filtros__ofertas">
				<div className="ofertas__titulo"><div className="ofertas__titulo-span">OFERTAS</div></div>
				<div className="ofertas__toggle-check">
					<Toggle
						checked={props.ofertas}
						onChange={props.ofertasOnChange} 
					/>
				</div>
			
			</div>
			<hr />
			<div className="filtros__ofertas">
				<div className="ofertas__titulo"><div className="ofertas__titulo-span">PREVIAJE</div></div>
					<div className="ofertas__toggle-check">
						<Toggle
						checked={props.previaje}
						onChange={props.previajeOnChange} 
						/>
					</div>
				</div>
			<div />
			<hr />
			<FiltrosSelectorPileta
				conPileta ={props.conPileta}
				sinPileta ={props.sinPileta}
				PiletaExclusiva ={props.PiletaExclusiva}
				Piletaclimatizada ={props.Piletaclimatizada}
				toggle={props.onClickCheck}
			/>
			<hr />
			<SelectorDeLugaresParaFiltros
				cochera={props.cochera} 
				dormitorios={props.dormitorios} 
				banos={props.banos} 
				onPlus={props.onPlus} 
				onMinus={props.onMinus}
			/>
			<hr />
			<FiltrosSelectorChecks
				conMascotas ={props.conMascotas}
				conJovenes ={props.conJovenes}
				wifi ={props.wifi}
				tarjeta={props.tarjeta}
				toggle={props.onClickCheck}
			/>
			<hr />

			{filtroLocalidad}
			{mostrarFiltroLocalidad ? <hr /> : null}

			<FiltrosCollapseChecks
				titulo={'SERVICIOS'}
				checks={checkServicio}
			/>
			<hr />

			<FiltrosCollapseChecks
				titulo={'EQUIPAMIENTOS'}
				checks={checkEquipamientos}
			/>
			<hr />

			<FiltrosCollapseChecks
				titulo={'SELECCIONES'}
				checks={checkSelecciones}
			/>
			<hr />

			<FiltrosCollapseInput
				titulo={'REFERENCIA'}
				autocomplete={inputReferencia}
			/>
			<hr />
			<FiltrosCollapseInput
				titulo={'BARRIO'}
				autocomplete={AutocompleteBarrio}
			/>
			<hr />
		</div>
	);
}