import React from 'react';
import take from 'lodash/take';
import filter from 'lodash/filter';
import find from 'lodash/find';
import differenceWith from 'lodash/differenceWith';
import { stringify } from 'rebem-classname';

const Agregado = ({valor, icono, texto}) => {	

	const iconoPara = (valor) => {
		switch(valor){
			case 2:
				return "glyphicon-remove";
			case 1:
				return "glyphicon-ok";
			case 3:
				return 'icon-question';
			default: 
				return 'icon-question';
		}
	}

	const estadoPara = (valor) =>{
		return {
			noDisponible:valor==2,
			disponible:valor==1,
			consultar:valor==3,
		}
	}

	const tituloPara = (valor) =>{
		switch(valor){
			case 2:
				return "No Disponible";
			case 1:
				return "Disponible";
			case 3:
				return "Consultar";
			default: 
				return "";
		}
	}

	const block = 'Agregado';
	return <div className={`col-xs-4 ${stringify({block, elem:"items"})}`}>
		<div className={stringify({block, elem:"imagen", mods:estadoPara(valor)})}>
			<span className={icono}/>
			<span className={"glyphicon " + iconoPara(valor)} title={tituloPara(valor)}/>	
		</div>
		<div className="ListadoDetalles__texto">
			<span>{texto}</span>
		</div>
	</div>;
}



const CabeceraAgregado = ({valor, icono, texto}) => {	
	const block = 'CabeceraAgregado';
	
	return <div className={`col-xs-6 ${stringify({block, elem:"items"})}`}>
		<div className={stringify({block, elem:"items-cont"})}>
			<div className={stringify({block, elem:"items-icon"})}><span className={"icono " + icono } /></div> 
			<div title={valor + ' ' + texto} className={stringify({block, elem:"items-texto"})}>{valor} {texto}</div>
		</div>
	</div>;
}

const AgregadoDormitorios = ({valor}) =>  <CabeceraAgregado valor={valor} icono="icon-cama" texto="Dorm."/> ;
const AgregadoBanios = ({valor}) =>  <CabeceraAgregado valor={valor} icono="icon-baño" texto="Baños"/> ;

export default function CardListadoDetalles(props){
	const block = "ListadoDetalles";

	const agregadosSeguros = [
		{key:"pileta", icono:"icon-pileta", texto:"PILETA", obs:"", value:props.pileta},
		{key:"cochera", icono:"icon-parking", texto:"COCHERA", obs:"", value:props.cochera},
		{key:"wifi", icono:"icon-wifi", texto:"WIFI", obs:"", value:props.wifi},
	];

	/* Prioridades para los dos agregados que faltan. (hablado con adri).*/
	const agregadosOrden = [
		{key:"aire_acondicionado", icono:"icon-aire-acondicionado", texto:"AIRE ACOND", obs:""},
		{key:"ropa_cama", icono:"icon-sabanas", texto:"ROPA DE CAMA", obs:""},
		{key:"ropa_blanca", icono:"icon-toalla", texto:"ROPA BLANCA", obs:""},
		{key:"desayuno", icono:"icon-desayuno", texto:"DESAYUNO", obs:""},
		{key:"ascensor", icono:"icon-ascensor", texto:"ASCENSOR", obs:""},
		{key:"asador", icono:"icon-asador", texto:"ASADOR", obs:""},
		{key:"tv", icono:"icon-tv", texto:"TELEVISION", obs:""},
	];

	const agregadosConValor = agregadosOrden.map((a) => {
		return {
			...a, 
			value:props[a.key]
		};
	});

	const agregadosConValorPositivo = filter(agregadosConValor, (av) => av.value == 1 || av.value == 3);

	let paraMostrar = take(agregadosConValorPositivo, 2);

	//si tengo dos, genial. Si no busco completar con algunos, aunque esten en negativo
	if(paraMostrar.length < 2){
		const meFaltan = 2-paraMostrar.length;

		//en el caso de que no se llegue a 2 agregados, meter estos dos (o uno)
		const usarEnElPeorDeLosCasos = [
			"aire_acondicionado",
			"tv"
		]
			//busco el que falta del array de ordenes
			.map(a => find(agregadosOrden, ao => ao.key==a))
			//les agrego el valor para poder mostrarlo
			.map(a => {return {...a, value:2}});

		//elijo de los que usar en el peor de los casos, los que no hayan sido usados ya
		const todaviaNoUsados = differenceWith(
			usarEnElPeorDeLosCasos, 
			paraMostrar, 
			(a,b)=>{
				return a.key==b.key
			}
		);

		//le agrego los que le faltaban al final
		paraMostrar = paraMostrar.concat(
			take(todaviaNoUsados, meFaltan)
		)
	}
	const toShow = agregadosSeguros.concat(paraMostrar);
	return(
		<div className={`row ${stringify({block})}`}>

			<AgregadoDormitorios valor={props.dormitorios} />
			<AgregadoBanios valor={props.banios} />

			{
				toShow.map((item,index)=>{
					return <div key={index}>
						<Agregado valor={item.value} icono={item.icono} texto={item.texto}/>
					</div>
				})
			}
		</div>
	);
}