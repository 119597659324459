import React from 'react';
import FotosHelper from '../../../helpers/FotosHelper';
import CardImagen from './CardImagen';
import Estrellas from '../../Commons/Estrellas';
import isMobile from 'ismobilejs';
import CardPrecio from './CardPrecio';
import PrecioHelper from '../../../helpers/PrecioHelper';
import find from 'lodash/find';
import { stringify } from 'rebem-classname';

export default function CardListadoPrincipal(props){
	const block = 'AnuncioCardListado';
	const propiedad = props.propiedad;
	let favorito = propiedad.favorito ? 'naranja' : 'gris';
	let fotoPrincipal = {
		small:  FotosHelper.imgParaPropiedad(propiedad.referencia, propiedad.foto_listado, 'listado_sm'),
		medium: FotosHelper.imgParaPropiedad(propiedad.referencia, propiedad.foto_listado, 'listado_md'),
	}

	let fotoPerfil = null;
	if (propiedad.foto_perfil) {
		fotoPerfil = <img className={stringify({block, elem:"perfil"})} src={ FotosHelper.imgParaPropiedad(propiedad.referencia, propiedad.foto_perfil, 'propietario_sm')} />;
	}

	const srcSet = fotoPrincipal.small + ' 320w, ' + fotoPrincipal.medium + ' 640w';
	const altImg = 'Alquiler ' + propiedad.tipo + ' en ' + propiedad.id_localidad;

	const llevanS = ['Apart', 'Bungalow', 'Cabaña', 'Casa', 'Chalet', 'Departamento', 'Estancia', 'Suite'];
	const llevaS = find(llevanS, (tipo) => tipo == propiedad.tipo);	

	let sAlFinal = '';
	if(propiedad.cantidadUnidades > 1){
		if(llevaS){
			sAlFinal = 's';	
		}
	}

	const contenido = (
		<div className={stringify({block, elem:"descripcion-texto"})}>
		<div className={stringify({block, elem:"item-nombre-propiedad"})}>
			<h3 className={stringify({block, elem:"item-nombre"})} title={propiedad.titulo}>{propiedad.titulo} ({propiedad.referencia})</h3> 
		</div>
		<div className={stringify({block, elem:"texto"})}>
			<span> { propiedad.tipo + sAlFinal } hasta { propiedad.capacidad_max } personas </span>
		</div>
		<div className={stringify({block, elem:"texto"})}>
			<span>{ propiedad.nombre_localidad }, { propiedad.nombre_provincia }</span>
		</div>
	</div>);
	
	let precio = propiedad.precio_minimo_calculado;
	let formattedPrecio = precio && PrecioHelper.distintoDeCero(precio) ? PrecioHelper.formatearPrecio(precio) : null;
	return(
		<div className="ListadoPrincipal" id="ListadoPrincipal">
			<CardImagen
				dataPush={props.dataPush}
				mostrar={props.mostrar}
				tieneOfertas={propiedad.tieneOfertas} 
				tieneOfertasUm={props.tieneOfertasUm}
				tieneOfertasFlexibles={props.tieneOfertasFlexibles} 
				onChangeCardListado={props.onChangeCardListado}
				fotoPrincipal={fotoPrincipal}
				propiedad={propiedad}
				fotoPerfil={fotoPerfil}
				altImg={altImg}
				srcSet={srcSet}				
				favorito={favorito}
				link={props.link}
				showModalFavorito={props.showModalFavorito}
				esEscapada={props.esEscapada}
			/>
			<div className={stringify({block, elem:"contenedor"})}>
				<div className={stringify({block, elem:"descripcion"})}>
					<a onAuxClick={props.dataPush} onClick={props.dataPush} href={props.link} target={isMobile.any ? '_self' : '_blank'}>{contenido}</a>
					{propiedad.cant_puntaje > 0 && 
						<div className={stringify({block, elem:"stars"})}>
							<Estrellas cantidad={propiedad.estrellas} evaluaciones={propiedad.cant_puntaje} texto textoAbajo={true}/>
						</div>
					}
				</div>
				<div className={stringify({block, elem:"precio"})}>
					<CardPrecio
						hayDesglose={props.hayDesglose}
						cargando={props.cargando}
						info={props.info}
						precio={formattedPrecio}
						cargo_extra_limpieza={propiedad.cargo_extra_limpieza}
						cargo_extra_otro={propiedad.cargo_extra_otro}
						estimado={propiedad.precio_estimado}
					/>
				</div>
			</div>
		</div>
	);
}