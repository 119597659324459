import React from 'react';
import { stringify } from 'rebem-classname';
import SelectorPlusMinus from '../../Commons/SelectorPlusMinus.jsx';

export default function SelectorDeLugaresParaFiltros(props){
	const block="SelectorDeLugaresParaFiltros";
	return (
		<div className={stringify({block})}>
			<SelectorPlusMinus 
				nombreProp="cochera"
				titulo="COCHERA"
				leyenda=""
				valor={props.cochera} 
				onPlus={props.onPlus} 
				onMinus={props.onMinus}
			/>
			<SelectorPlusMinus 
				nombreProp="dormitorios"
				titulo="DORMITORIOS"
				leyenda=""
				valor={props.dormitorios} 
				onPlus={props.onPlus} 
				onMinus={props.onMinus}
			/>
			<SelectorPlusMinus 
				nombreProp="banos"
				titulo="BAÑOS"
				leyenda=""
				valor={props.banos} 
				onPlus={props.onPlus} 
				onMinus={props.onMinus}
			/>
		</div>
	);
}