import React from "react";
import { stringify } from "rebem-classname";
export function DisplayListadoSinPropiedadesMobile({
  block,
  abrirFiltros
}) {
    return <div className={stringify({block, elem:"contenedor-sin-propiedad"})}>
        <div className={stringify({block, elem:"titulo-sin-propiedad"})}>
            <div>No se encontraron propiedades</div>
        </div>
        <div className={stringify({block, elem:"subtitulo-sin-propiedad"})}>
            <div>Trata de ajustar tu busqueda modificando los filtros</div>
        </div>
        <button type="button" onClick={abrirFiltros} className={`btn btn-celeste ${stringify({ block, elem:"boton-eliminar-filtros"})}`}>Cambiar Filtros</button>
    </div>;
}