import React from "react";
import { stringify } from "rebem-classname";
export function DisplayListadoLocalidadesCercanasMobile({
  block,
  estaCargando,
  dataLocalidadesCercanas
}) {
  return <div className={`row ${stringify({block, elem:"display"})}`}>
        <div className={`col-xs-12 ${stringify({block, elem:"listado", mods:{cargando: estaCargando}})}`}>
            <div className={stringify({block, elem:"titulo-listado-loc-cercanas"})}>No hay alojamientos en esta localidad, le recomendamos las siguientes localidades cercanas</div>
            <div className="row">
                {dataLocalidadesCercanas.map(localidad => <div key={localidad.id} className={`col-md-4 col-sm-6 col-xs-12 ${stringify({block, elem:"card-loc-cercanas"})}`}>
                    <div className={stringify({block, elem:"contenedor-loc-cercanas"})}>
                        <h2 className={stringify({block, elem:"titulo-loc-cercanas"})}>{localidad.localidad}</h2>
                        <div className={stringify({block, elem:"info-loc-cercanas"})}> a {localidad.distanciaKm.toFixed(2)}km de distancia</div>
                        <div className={stringify({block, elem:"info-loc-cercanas"})}> 
                            {localidad.cantPropiedades} {localidad.cantPropiedades > 1 ? 'Alojamientos' : 'Alojamiento'}
                        </div>
                        <div className={stringify({block, elem:"boton-loc-cercanas"})}>
                            <a href={localidad.link} target='_blank' className="btn btn--sin-radius btn-naranja">Ver Alojamientos</a>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    </div>;
}
  