import React from 'react';
import { stringify } from 'rebem-classname';
import { BsFillStarFill } from 'react-icons/bs'

//cantidad es la cantidad de estrellas (o sea, el promedio), evaluaciones el número total (por ejemplo, 150)
export default function Estrellas({cantidad, evaluaciones, texto, textoAbajo, aprox}){
	const block = 'estrellas';
	const textoEstrella = 
		texto ? 
		<span className={stringify({block, elem:"stars-texto", mods:{orden:textoAbajo}})}>
			{aprox && '+'}{evaluaciones} {evaluaciones > 1 ? 'valoraciones' : 'valoración'}
		</span> 
		: null;

	const estrellas=[];
	let naranja = false;
	for (let i = 0; i < 5; i++) {
		if(i < cantidad){
			naranja = true;
		}else{
			naranja = false
		}
		estrellas.push(<BsFillStarFill key={i} className={stringify({block, mods:{naranja}})} aria-hidden="true" />);
	}
	return estrellas.length!=0 ? <div>{estrellas}{textoEstrella}</div> : null;
}