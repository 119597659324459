import React from 'react';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import isMobile from 'ismobilejs';
import queryString from 'query-string';
import { stringify } from 'rebem-classname';

const sugDefault = [];
export default class AutocompleteBarrios extends React.Component{
	constructor(props) {
		super(props);

		this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
		this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
		this.getSuggestionValue = this.getSuggestionValue.bind(this);
		this.onChange = this.onChange.bind(this);
		this.renderSuggestion = this.renderSuggestion.bind(this);
		this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
		this.restablecer = this.restablecer.bind(this);
		
		this.state = {
			suggestions:sugDefault,
			textoDelInput: props.barrio ? props.barrio : '',
		}
	}

	restablecer(){
		this.setState({
			textoDelInput: ''
		}, this.props.onLugarClear);
		this.props.onLugarSelected(null);
		this.inputName.input.focus();
	}
	
	onChange(event, {newValue}){
		this.setState({
			textoDelInput: newValue
		});
	};

	onSuggestionsFetchRequested({value, reason}){
		let valor=value;
		if(reason=="input-focused"){
			valor="";
		}
		let lugar = {};
		if(this.props.lugar){		
			switch(this.props.lugar.tipo){
				case 'localidad':
					lugar.localidad=this.props.lugar.nombre;
					lugar.provincia=this.props.lugar.provincia;
					break;
				case 'ciudad':
					lugar.localidad=this.props.lugar.nombre;
					lugar.provincia=this.props.lugar.pais;
					break;			
				case 'provincia':
				case 'país':
					lugar.provincia=this.props.lugar.nombre;
					break;
				default:
					lugar = null;
			}
		}
		let stringLugar = queryString.stringify(lugar);
		//TODO: Agregar manejo de prov y loc al buscador de barrio
		//console.log('getUrlFiltros', getUrlFiltros());
		axios
		.get("/api/v1/barrios/?q=" + valor + (stringLugar?"&" + stringLugar:""))
		.then((result) => {
			let rst = result.data;
			
			if(!rst.length){
				rst=sugDefault;
			}
			this.setState({
				suggestions: rst
			});
		})
		.catch((err) => console.log(err));
	};
	/**
	 * Implement it to teach Autosuggest what should be the input value when suggestion is clicked.
	 */
	getSuggestionValue(lugar){
		return lugar.barrio;
	}

	onSuggestionsClearRequested(){
		this.setState({
			suggestions: sugDefault
		});
	};

	onSuggestionSelected(event, { suggestion, suggestionValue }){
		this.setState({
			textoDelInput:suggestion?suggestion.barrio:'',
		}, () => this.props.onLugarSelected(suggestion));
	}
	/**
	 * Use your imagination to define how suggestions are rendered.
	 */
	renderSuggestion(item){
		return <div><strong>{item.barrio}</strong></div>
	};

	componentWillReceiveProps(newProps){
		if(newProps.barrio!=this.state.textoDelInput){
			this.setState({
				textoDelInput:newProps.barrio?newProps.barrio:'',
			});
		}
	}

	render(){
		const inputProps = {
			placeholder: 'a dónde vamos?',
			value:this.state.textoDelInput,
			onChange: this.onChange
		};
		const block = "AutocompleteBarrios";
		return(
			<div className={stringify({block})}>
				<Autosuggest
					suggestions={this.state.suggestions}
					onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.onSuggestionsClearRequested}
					onSuggestionSelected={this.onSuggestionSelected}
					getSuggestionValue={this.getSuggestionValue}
					renderSuggestion={this.renderSuggestion}
					inputProps={inputProps}
					//highlightFirstSuggestion={true}
					focusInputOnSuggestionClick={!isMobile.any}
					shouldRenderSuggestions={()=>true}
					ref={(inputName) => this.inputName = inputName}
				/>
				<div className={stringify({block, elem:"iconos", mods:{clearInput:true}})}>
					{this.state.textoDelInput!="" ? <span className="glyphicon glyphicon-remove" onClick={this.restablecer}/> : null}
				</div>
			</div>
		)
	}
}