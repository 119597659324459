import React from 'react';
import { Collapse } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

export default class FiltrosCollapseInput extends React.Component{
	constructor(props) {
		super(props);
		this.openCollapseFiltros = this.openCollapseFiltros.bind(this);
		
		this.state = {
			show:false,
		}		
	}
	openCollapseFiltros(){
		this.setState({
			show: !this.state.show,
		});
	}
	render(){
		return (
			<div className="collapse-input">
				<a onClick={this.openCollapseFiltros}>
					<div className="collapse-input__cabecera">
							<div className="collapse-input__titulo collapse-input__inline">{this.props.titulo}</div>
							<div className="collapse-input__boton collapse-input__inline collapse-input__izq">
								<span className="collapse-input__ver-mas" >Ver más</span>
								<span>{this.state.show ? <IoIosArrowUp /> : <IoIosArrowDown />}</span>
							</div>
					</div>
				</a>
				<Collapse in={this.state.show}>
					<div className="collapse-input_container">
						<div className="collapse-input">
							<div className="collapse-input__items">
								<div className="collapse-input__item">
									{this.props.autocomplete}
								</div>
							</div>
						</div>
					</div>
				</Collapse>
			</div>
		);		
	}
}