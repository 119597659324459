import React from 'react';
import OfertaFlexibleHelper from '../../../helpers/OfertaFlexibleHelper';
import PrecioHelper from '../../../helpers/PrecioHelper';
import moment from 'moment';
import { stringify } from 'rebem-classname';

export default function CardListadoOfertaFlexible(props){
    const _helper = new OfertaFlexibleHelper();
	const block = 'CardListadoOfertaFlexible';
    const p = props.propiedad;
    const ofertas = _helper.getOfertaAMostrar(p.ofertas_flexibles);
    const fecha_inicio = moment(ofertas.fecha_inicio).format('DD/MM/YYYY');
    const fecha_fin = moment(ofertas.fecha_fin).format('DD/MM/YYYY');

	return(
		<div className={stringify({block})} onClick={props.onChangeCardListado}>
			<div className={stringify({block, elem:"encabezado"})}>
                <div className={stringify({block, elem:"icono"})}>
                    <span className="icon-coupon"/>
                </div>
                <div className={stringify({block, elem:"titulo"})}>
                    <h4 className={stringify({block, mods:{conMapa: props.vistaDual}, elem:"titulo-oferta"})}>¡OFERTA FLEXIBLE!</h4>
                </div>
                <div className={stringify({block, elem:"icono"})}>
                    <span className="icon-coupon-mirror"/>
                </div>
            </div>
            <div className={stringify({block, mods:{conMapa: props.vistaDual}, elem:"posibilidadesOferta"})}>
                {
                    ofertas.oferta_flexible_items.map((items, index)=>{
                        return <p key={index}><span className={stringify({block, elem:"rojoPastel"})}>{items.cantidad_noches} noches</span> por <span className={stringify({block, elem:"rojoPastel"})}>{PrecioHelper.formatearPrecio(items.precio)}</span> finales para {items.cantidad_personas}</p>
                    })
                }
                {
                    ofertas.agregado_extra ? <p className={stringify({block, elem:"agregadoExtra"})}>+ {ofertas.agregado_extra}!</p> : null
                }
            </div>
            <div className={stringify({block, elem:"fechasEstadia"})}>
                <p>Uso del <span className={stringify({block, elem:"fechas"})}>{fecha_inicio}</span> al <span className={stringify({block, elem:"fechas"})}>{fecha_fin}</span></p>
            </div>
		</div>
	);
}