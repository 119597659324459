import moment from 'moment';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';

export default class OfertaFlexibleHelper{
	getOfertaAMostrar(ofertas){
        const ordered = orderBy(ofertas, ['fecha_inicio']);
		return find(ordered,(oferta) => {
			return orderBy(oferta.fecha_inicio, ['fecha_inicio']) && moment().isSameOrBefore(moment(oferta.fecha_fin), 'day');
		});
	}
}