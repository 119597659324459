const styles = {
  precio: {
    fontSize: 24,
    fontWeight: 900,
    whiteSpace: "nowrap",
    flexWrap: "nowrap",
  },
  precioSmall: {
    fontSize: 16,
    color: "gray",
    marginBlockStart: "auto",
    marginInlineStart: "auto",
    width: "fit-content",
  },
  precioTag: {
    fontSize: 12,
    fontWeight: 400,
    color: "gray",
    marginBlockStart: "auto",
    marginBlockEnd: 5,
  },
  precioTagSmall: {
    marginBlockEnd: "initial",
  },
};

export default styles;
