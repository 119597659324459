import moment from 'moment';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';

export default class OUMHelper{
	getOfertaAMostrar(ofertas, idOferta = null){
		if (idOferta) {
			return find(ofertas, oferta => oferta.id == idOferta);	
		}
		const ordered = orderBy(ofertas, ['fecha_inicio']);
		return find(ordered,(oferta) => {
			return moment().isBetween(oferta.fecha_inicio, oferta.fecha_fin, 'day', '[]');
		});
	}
}