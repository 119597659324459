import React from 'react';
import 'rc-checkbox/assets/index.css';
import Checkbox from 'rc-checkbox';
import { stringify } from 'rebem-classname';

export default function FiltrosSelectorTipoPropiedad(props){
	const block = "selector-tipo-propiedad";
	return <div className={stringify({block, mods:{hayTroya:props.hayTroya}})}>
		<div className="selector-tipo-propiedad__titulo">TIPO DE PROPIEDAD</div>
		<div className="selector-tipo-propiedad__checks">
			<div className="selector-tipo-propiedad__item-check">
				<label className="selector-tipo-propiedad__label">
					<Checkbox
						name={'Casas'}
						checked={props.Casas}
						onClick={props.toggle}
					/>
					<span className="selector-tipo-propiedad__label-text">Casas o Chalets</span>
				</label>
			</div>
			<div className="selector-tipo-propiedad__item-check">
				<label className="selector-tipo-propiedad__label">
					<Checkbox
						name={'Departamentos'}
						checked={props.Departamentos}
						onClick={props.toggle}
					/>
					<span className="selector-tipo-propiedad__label-text">Departamentos o Dúplex</span>
				</label>
			</div>
			<div className="selector-tipo-propiedad__item-check">
				<label className="selector-tipo-propiedad__label">
					<Checkbox
						name={'Cabanas'}
						checked={props.Cabanas}
						onClick={props.toggle}
					/>
					<span className="selector-tipo-propiedad__label-text">Cabañas o Bungalows</span>
				</label>
			</div>
			<div className="selector-tipo-propiedad__item-check">
				<label className="selector-tipo-propiedad__label">
					<Checkbox
						name={'Aparts'}
						checked={props.Aparts}
						onClick={props.toggle}
					/>
					<span className="selector-tipo-propiedad__label-text">Aparts, Resorts y Suites</span>
				</label>
			</div>
			<div className="selector-tipo-propiedad__item-check">
				<label className="selector-tipo-propiedad__label">
					<Checkbox
						name={'Hoteleria'}
						checked={props.Hoteleria}
						onClick={props.toggle}
					/>
					<span className="selector-tipo-propiedad__label-text">Hotelería</span>
				</label>
			</div>
			<div className="selector-tipo-propiedad__item-check">
				<label className="selector-tipo-propiedad__label">
					<Checkbox
						name={'Quintas'}
						checked={props.Quintas}
						onClick={props.toggle}
					/>
					<span className="selector-tipo-propiedad__label-text">Quintas o Estancias</span>
				</label>
			</div>
			<div className="selector-tipo-propiedad__item-check">
				<label className="selector-tipo-propiedad__label">
					<Checkbox
						name="Motorhomes"
						checked={props.Motorhomes}
						onClick={props.toggle}
					/>
					<span className="selector-tipo-propiedad__label-text">Motorhomes o Rodantes</span>
				</label>
			</div>
			<div className="selector-tipo-propiedad__item-check">
				<label className="selector-tipo-propiedad__label">
					<Checkbox
						name="Glampings"
						checked={props.Glampings}
						onClick={props.toggle}
					/>
					<span className="selector-tipo-propiedad__label-text">Glampings</span>
				</label>
			</div>
		</div>
	</div>;
}