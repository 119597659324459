import React from 'react';
import OUMHelper from '../../../helpers/OUMHelper';
import moment from 'moment';
import { stringify } from 'rebem-classname';
import { ImFire } from 'react-icons/im';

export default function CardListadoOferta(props){
	const _helper = new OUMHelper();
	const block = 'ListadoOferta';
	const p = props.propiedad;
	const oferta = _helper.getOfertaAMostrar(p.ofertas_um);
	if(!oferta){
		return null;
	}
	const cantNoches = moment(oferta.fecha_egreso).diff(oferta.fecha_ingreso,'days')
	const fechaIngreso = moment(oferta.fecha_ingreso).format('DD/MM/YYYY');
	const fechaEgreso = moment(oferta.fecha_egreso).format('DD/MM/YYYY');

	return(
		<div className={stringify({block})} onClick={props.onChangeCardListado}>
			<div className={stringify({block, elem:"icono"})}>
				<ImFire />
			</div>
			<div className={stringify({block, elem:"titulo"})} title="Para cerrar toque aquí">
				<div  data-selected={'Principal'}>OFERTAS</div>
				<div  data-selected={'Principal'}>IMPERDIBLES</div>  
			</div>
			<div className={stringify({block, elem:"icono", mods:{flip:true}})}>
				<ImFire />
			</div>
			<hr/>
			<div className={stringify({block, elem:"fecha"})}>
				<span>Ingreso: <strong>{fechaIngreso}</strong></span>
			</div>
			<div className={stringify({block, elem:"fecha"})}>
				<span>Egreso: <strong>{fechaEgreso}</strong></span>
			</div>
			<hr/>
			<div className={stringify({block, elem:"descripcion"})}>
				<div>
					<span>¡{cantNoches} noches por </span>
					<span className={stringify({block, elem:"precio"})}>${parseInt(oferta.precio)}</span>
				</div>
				<span> finales para {oferta.capacidad} personas!</span>
			</div>
		</div>
	);
}