import React from 'react';
import { FaHeart } from 'react-icons/fa'; 
import { stringify } from 'rebem-classname';
import ajax from '../../helpers/ajax';
import HelperTurista from '../../helpers/HelperTurista';
import Context  from '../Login/ContextUser/Context';

export default class Favorito extends React.Component{
	constructor(props) {
		super(props);
		this.onClickFavorito = this.onClickFavorito.bind(this);
		this.cambiarFavorito = this.cambiarFavorito.bind(this);
		this.state = {
			favorito:this.props.fav ? this.props.fav : false,
			cargando:false
		}
	}
	
	cambiarFavorito() {
		const ht = HelperTurista.create();
		let p = null;

		if(!this.state.favorito){
			p = ht.quitarFavorito(this.props.referencia, this.context.state.email);
		}
		else{
			p = ht.agregarFavorito(this.props.referencia, this.context.state.email); 
		}
		p.then((result) => {
			const resultado = result.data;
			this.setState({
				favorito: resultado.favorito,
				cargando: false,
			});
		}) 
		.catch((responseError) => { 
			this.setState({
				cargando: false
			});
			ajax.error(responseError);
		});
	}
	onClickFavorito(){
		

		if (!this.context.state.email) {
			this.props.showModalFavorito();
			return;
		} 

		if(this.state.cargando){
			return;
		}
	
		this.setState({
			cargando: true,
			favorito: !this.state.favorito,
		}, this.cambiarFavorito);
	}
	render(){
		const block = 'Favorito';
		return(	
			<div onClick={this.onClickFavorito} className={stringify({block, mods:{conFondo:this.props.conFondo,fav:this.state.favorito,heightFav: this.props.heightFav}})}>
				<FaHeart title={this.state.favorito?"Quitar de favoritos":"Agregar a favoritos"}/>
			</div>
		);
		
	}
}
Favorito.contextType = Context;