import React from 'react';
import MapaParaModal from '../Mapa/MapaParaModal';

export default class ModalMapaListado extends React.Component{
	constructor(props) {
		super(props);
		this.onChangeClick = this.onChangeClick.bind(this);
		this.AbrirMapaPie = this.AbrirMapaPie.bind(this);
		this.CerrarMapaPie = this.CerrarMapaPie.bind(this);

		this.state = {
			referencia: null,
			mostrarMapaPie: false
		}
	}
	AbrirMapaPie(){
		this.setState({
			mostrarMapaPie: true
		});
	}
	CerrarMapaPie(){
		this.setState({
			mostrarMapaPie: false
		});
		this.setState({
			referencia : ''
		});
	}
	onChangeClick(referencia){
		this.AbrirMapaPie()
		this.setState({
			referencia
		});
	}
	render(){
		const ancho = window.innerWidth; 
		const tamañoTitulo = 60;
		const mapaHeight = window.innerHeight - tamañoTitulo;
		const sizeOfMap = {
			width: ancho/3.33,
			height: mapaHeight,
		};

		let propMostrar = null;
		if(this.state.referencia!=null){
			propMostrar = this.state.referencia;
		}

		return(
			<div style={{display:'inline-block'}}>
				<MapaParaModal
					mapaOpen={this.props.mapaOpen}
					filtros={this.props.filtros}
					lugar={this.props.lugar}
					page={this.props.page}
					urlKey="mm"
					mapaHeight={mapaHeight}
					sizeOfMap={sizeOfMap}
					onChangeClick={this.onChangeClick}
					propMostrar={propMostrar}
					mostrarMapaPie={this.state.mostrarMapaPie}
					CerrarMapaPie={this.CerrarMapaPie}
				/>
			</div>
		);
	}
}

